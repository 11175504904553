<template>
  <CToaster placement="top-end">
    <CToast autohide="5000" :class="toast.cus_class" v-for="toast in toasts" :key="toast.content">
      <CToastHeader closeButton>
        <svg
          class="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#41c847"></rect>
        </svg>
        <span class="me-auto fw-bold">{{ toast.data.nickname }} ({{ toast.data.bookingDate }} {{ toast.data.start_time }}-{{ toast.data.end_time}})</span>
      </CToastHeader>
      <CToastBody><span class="me-auto fw-bold" style="color:#404345;">{{ toast.content }}</span></CToastBody>
    </CToast>
  </CToaster>
  <b-alert v-show="version_update" show variant="warning">有新的系統版本! 5秒後將重新載入</b-alert>
  <b-alert v-show="auto_update_msg" show variant="warning">資料同步中!!!</b-alert>
  <b-alert v-show="therapist_version" show variant="warning">4秒後將重新載入理療師版面!</b-alert>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            style="display: none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              <!--
              <b-button
                variant="secondary"
                href="./#/Dashboard"
                @click="home_page()"
              >
                {{ $t('common.button.home') }}
              </b-button>
              -->
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->

  <!-- Modal for Request-->
  <div>
    <b-button
      id="requestModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#requestModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="requestModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="requestModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="requestModalLabel" class="modal-title">技術支援</h5>
          <button
            id="requestModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span style="color:red;">請輸入問題 / 要求。IT部將稍後處理。</span>
              <br>
              <CFormInput
                v-model="request_text"
                size="sm"
                @keyup.enter="action_auth('request')"
              />
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="action_auth('request')">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Request-->

  <!-- Modal for Timer-->
  <div>
    <b-button
      id="timerModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#timerModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="timerModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="timerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="timerModalLabel" class="modal-title">儀器超時</h5>
          <button
            id="timerModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="close_timer()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span style="color:red;">請處理以下超時儀器。</span>
              <br>
            </div>
          </div>
          <br>
          <template v-for="item in timeout_list" :key="item.id">
            <div class="row">
              <div class="col-6 text-center">
                <span>
                  儀器: {{ getlistitem('machine_sku', item.machine_id) }}
                </span>
              </div>
              <div class="col-6 text-center need_update">
                <span>
                  房間: {{ getlistitem('room', item.room_id) }}
                </span>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Timer-->

  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-1 text-left">
        <b-button style="background-color: white !important; border-radius: 2rem !important; color:rgb(4, 97, 66) !important;" href="./#/Dashboard" class="mx-1" variant="outline-light">
          <font-awesome-icon :icon="['fas', 'house-user']" size="2x"/>
        </b-button>
      </div>
      <div class="col-3 text-left">
        <b-button
          class="mx-1 position-relative"
          v-if="role_check(14)"
          size="sm"
          variant="outline-secondary"
          @click="loadBookingPDF()"
          >
            <span>列印Booking</span>
        </b-button>
        <b-button
          class="mx-1 position-relative"
          v-if="role_check(1)"
          size="sm"
          variant="outline-secondary"
          @click="gen_report()"
          >
            <span>Hourly Booking Report</span>
        </b-button>
        <b-button
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#functionlistoffcanvas"
          >
            <span>功能表</span>
          </b-button
        >
      </div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <span>預約表 ({{ branch }}) - PC version</span>
          </div>
        </div>
      </div>
      <div class="col-1 text-right">
        <b-button
          class="mx-1 position-relative"
          variant="outline-secondary"
          @click="change_mode()"
          size="sm"
        >
          <span :class="followup_mode ? 'need_update' : ''">Follow-up模式</span>
        </b-button>
      </div>
      <div class="col-2 text-right">
        <b-button
          class="mx-1 position-relative"
          v-if="role_check(16)"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#rosteroffcanvas"
          >
            <span>Roster更表</span>
            <b-badge variant="light" text-indicator style="transform: translate(0%, 0%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.4rem !important;">
              <span v-if="business_day_roster.length > 0" style="color: green;"><font-awesome-icon :icon="['fas', 'check']" size="2x"/></span>
              <span v-else style="color: red; font-size: 1rem !important;">X</span>
            </b-badge>
        </b-button>
      </div>
      <div class="col-1 text-right">
        <span style="font-size: 13px;">Version {{ version }}&nbsp;</span>
      </div>
    </div>

    <div id="fullscreen" class="bg-white">
    <!-- Modal for Event -->
    <div
      id="eventModal"
      class="modal fade"
      data-bs-keyboard="false"
      tabindex="-1"
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">
              <span v-if="eventAction == 'create'">新預約</span>
              <span v-if="eventAction != 'create'">修改預約 (Booking ID:{{ eventInfo.id }})</span>
            </h5>
            <b-button
              variant="danger"
              @click="action_request()"
              v-if="eventAction != 'create'"
            >
              <span>技術支援</span>
            </b-button>
          </div>
          <div class="modal-body">
            <div v-if="eventInfo">
              <div v-if="eventInfo.special_event > 0">
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>類型</span>
                  </div>
                  <div class="col-3 text-left">
                    <Multiselect
                        v-model="eventInfo.special_event"
                        mode="single"
                        :close-on-select="true"
                        :searchable="false"
                        :create-option="false"
                        :options="special_event_list"
                        :allow-empty="false"
                        :canClear="false"
                        :canDeselect="false"
                        valueProp="id"
                        trackBy="id"
                        label="event"
                      />
                  </div>
                  <div class="col-3 text-left">
                    <b-form-checkbox
                      v-if="eventInfo.special_event == 2"
                      v-model="allday"
                      value="1"
                      unchecked-value="0"
                    >
                      <span id="allday">全日</span>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left"></div>
                  <div class="col-3 text-center">
                    <span>備註</span>
                  </div>
                  <div class="col-6 text-left">
                    <CFormInput
                      id="remark"
                      ref="remark"
                      v-model="eventInfo.remark"
                      size="sm"
                      type="text"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>理療師</span>
                  </div>
                  <div class="col-3 text-left">
                    <div v-if="eventInfo.id > 0">
                      <span>{{ getlistitem('therapist', eventInfo.org_therapist) }}</span>
                    </div>
                    <div v-else>
                      <Multiselect
                        v-model="eventInfo.org_therapist"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="therapist_list"
                        :allow-empty="false"
                        :canClear="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                  </div>
                  <div class="col-3 text-left">
                    <b-form-checkbox
                      v-model="allstaff"
                      value="1"
                      unchecked-value="0"
                      v-if="eventInfo.id == 0"
                    >
                      <span id="allstaff">全部理療師</span>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>開始時間</span>
                  </div>
                  <div class="col-3 text-left">
                    <datepicker
                      v-model="eventInfo.bookingStartTime"
                      size="sm"
                      :clearable="false"
                      timePicker
                      minutesGridIncrement="30"
                      :min-time="{ hours: 11}"
                      :max-time="{ hours: 22, minutes: 30}"
                      auto-apply
                    />
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>結束時間</span>
                  </div>
                  <div class="col-3 text-left">
                    <datepicker
                      v-model="eventInfo.bookingEndTime"
                      size="sm"
                      :clearable="false"
                      timePicker
                      minutesGridIncrement="30"
                      :min-time="{ hours: 11}"
                      :max-time="{ hours: 22, minutes: 30}"
                      auto-apply
                    />
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-3 text-left">
                    <span>會員編號</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>名稱</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>別名</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>性別</span>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <CFormInput
                        id="member_no"
                        ref="member_no"
                        v-model="eventInfo.member_no"
                        size="sm"
                        type="text"
                        @change="member_check(eventInfo)"
                        :readonly="eventAction == 'edit'"
                      />
                    </div>
                    <div class="col-3 text-left">
                      <span :style="vaild_member ? '' : 'color:red;'">{{ eventInfo.name }}</span>
                    </div>
                    <div class="col-3 text-left">
                      <CFormInput
                      id="nickname"
                      ref="nickname"
                      v-model="eventInfo.nickname"
                      size="sm"
                      type="text"
                    />
                    </div>
                    <div class="col-3 text-left">
                      <b-form-radio-group
                        v-model="eventInfo.gender"
                        :options="gender_list"
                        value-field="value"
                        text-field="name"
                        disabled-field="notEnabled"
                        @change="room_check()"
                      ></b-form-radio-group>
                    </div>
                  </div>
                  <div class="row" v-show="eventInfo.block_list" :style="{ 'background-color': eventInfo.block_list_color}">
                    <div class="col-12 text-left">
                      <img :src="'./triangle_exclamation.svg'" width="30" height="25"/><b style="color:white; white-space:break-spaces;">{{ eventInfo.block_list_color_desc }}</b>
                      <br>
                      <b v-show="eventInfo.block_list_remark" style="color:white; white-space:break-spaces;">{{ eventInfo.block_list_remark }}</b>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>預約日期</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>開始時間</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>結束時間</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.bookingDate"
                        size="sm"
                        auto-apply
                        input-format="yyyy-MM-dd"
                        :clearable="false"
                        :enable-time-picker="false"
                        @update:modelValue="change_bookingDate(eventInfo)"
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.bookingStartTime"
                        size="sm"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="30"
                        :min-time="{ hours: 11}"
                        :max-time="{ hours: 22, minutes: 30}"
                        auto-apply
                        @update:modelValue="change_bookingStartTime()"
                        textInput
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.bookingEndTime"
                        size="sm"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="30"
                        :min-time="{ hours: 11}"
                        :max-time="{ hours: 22, minutes: 30}"
                        auto-apply
                        @update:modelValue="change_bookingEndTime()"
                        textInput
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                  </div>
                  <div class="row" v-show="full_room">
                    <div class="col-12 text-left">
                      <span style="color:red;">{{ full_room }}</span>
                    </div>
                  </div>
                  <br>
                  <div class="row" v-if="eventAction == 'edit' && eventInfo.created_from != '1'">
                    <div class="col-6 text-left">
                      <span>Call Team備註: {{ eventInfo.remark_call }}</span>
                    </div>
                    <div class="col-6 text-left">
                      <span>種類: {{ getlistitem('member_type', eventInfo.member_type) }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <span>備註</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <CFormInput
                        id="remark"
                        ref="remark"
                        v-model="eventInfo.remark"
                        size="sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <span>內部備註 (不會顯示在電話版面)</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <CFormInput
                        id="remark_internal"
                        ref="remark_internal"
                        v-model="eventInfo.remark_internal"
                        size="sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <span>理療師</span>
                    </div>
                    <div class="col-9 text-left">
                      <span></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.org_therapist"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="therapist_list"
                        :allow-empty="false"
                        :canClear="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                        @input="therapist_check()"
                        :disabled="eventInfo.checkout_time"
                      />
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.new_join"
                        value="1"
                        unchecked-value="0"
                        @change="therapistOption('new_join')"
                      >
                        <span>新會員</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.specify"
                        value="1"
                        unchecked-value="0"
                        @change="therapistOption('specify')"
                      >
                        <span>指定理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.recommend"
                        value="1"
                        unchecked-value="0"
                        @change="therapistOption('recommend')"
                      >
                        <span>推介理療師</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="row" v-show="therapist_booked">
                    <div class="col-12 text-left">
                      <span style="color:red;">{{ therapist_booked }}在該時段已有booking</span>
                      <b-form-checkbox
                        v-model="update_sync_delete"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>更新時將{{ therapist_booked }}在該時段的原有Booking轉為</span><span style="color:red;">舊客Waiting List</span>
                      </b-form-checkbox>
                      <br>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left" v-if="eventAction == 'edit'">
                      <span>Re Confirm</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left" v-if="eventAction == 'edit'">
                      <b-form-radio-group
                        v-model="eventInfo.reconfirm"
                        :options="reconfirm_list"
                        value-field="value"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-radio-group>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventAction == 'edit' && !eventInfo.checkout_time">
                      <span>到達時間</span>&nbsp;
                      <b-button
                        v-if="!eventInfo.checkout_time"
                        variant="secondary"
                        @click="arrival_now()"
                      >
                        <span>Now</span>
                      </b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventAction == 'edit'">
                      <datepicker
                        v-model="eventInfo.arrivalTime"
                        size="sm"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="5"
                        auto-apply
                        textInput
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>營養師</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>中醫師</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>儀器機</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.nutritionist"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="nutritionist_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.chinese_medicine"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="chinese_medicine_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.machine"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="machine_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="machine"
                        label="machine"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventInfo.checkin_time">
                      <span>Check-in時間</span>
                    </div>
                    <div class="col-3 text-left">
                      <span>房號</span>
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time) && eventInfo.org_therapist.length > 1">
                      <span></span>
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)">
                      <span></span>
                    </div>
                    <div class="col-3 text-left">
                      <span>顧問</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b-form-checkbox
                        style="display: inline-block !important;"
                        v-model="eventInfo.without_advisor"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>不輪</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventInfo.checkin_time">
                      <span>{{ eventInfo.checkin_date }} {{ eventInfo.checkin_time }}</span>
                      <span v-show="eventInfo.without_therapist == 1" style="color:red;">&nbsp;&nbsp;未有理療師</span>
                      <span v-show="eventInfo.without_therapist == 2" style="color:red;">&nbsp;&nbsp;有部份理療師</span>
                    </div>
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.room"
                        mode="single"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="room_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="room"
                        label="room"
                      />
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time) && eventInfo.org_therapist.length > 1">
                      <b-form-checkbox
                        v-model="eventInfo.without_therapist"
                        value="2"
                        unchecked-value="0"
                      >
                        <span>部份理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)">
                      <b-form-checkbox
                        v-model="eventInfo.without_therapist"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>房間沒有理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.advisor"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="advisor_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.checkout_time">
                    <div class="col-4 text-left">
                      <span>Check-out時間</span>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.checkout_time">
                    <div class="col-4 text-left">
                      <span>{{ eventInfo.checkout_date }} {{ eventInfo.checkout_time }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.cancel_reason">
                    <div class="col-12 text-left">
                        <span style="color:red;">取消原因: {{ getlistitem('cancel_reason', eventInfo.cancel_reason) }}</span><span v-if="eventInfo.cancel_reason == 8"> ({{eventInfo.cancel_reason_remark}})</span>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <!-- Cancel button -->
                  <b-button
                    variant="danger"
                    @click="cancel_booking()"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && !eventInfo.arrival_time"
                  >
                    <span>取消預約</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && !eventInfo.arrival_time">&nbsp;</span>
                  <!-- End cancel button -->

                  <!-- Back button -->
                  <b-button
                    variant="secondary"
                    @click="back()"
                  >
                    {{ $t('common.button.back') }}
                  </b-button>
                  <span>&nbsp;&nbsp;</span>
                  <!-- End back button -->

                  <!-- Submit button -->
                  <b-button v-if="((eventInfo.member_no && vaild_member) || eventInfo.special_event > 0) && eventInfo.org_therapist.length > 0 && (eventInfo.bookingStartTime.hours != eventInfo.bookingEndTime.hours || eventInfo.bookingStartTime.minutes != eventInfo.bookingEndTime.minutes) && !eventInfo.checkout_date" variant="success" @click="action_auth('submit')">
                    <span v-if="eventInfo.id == 0">{{ $t('common.button.submit') }}</span>
                    <span v-if="eventInfo.id > 0">{{ $t('common.button.update') }}</span>
                    <span v-if="eventInfo.status != 1">{{ $t('common.button.booking') }}</span>
                  </b-button>

                  <!-- 後補 button -->
                  <b-button
                    variant="success"
                    @click="action_auth('submit')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && eventInfo.checkout_time && eventInfo.without_therapist == '0'"
                  >
                    <span>後補資料</span>
                  </b-button>
                  <!-- End 後補 button -->

                  <span v-if="(eventInfo.member_no || eventInfo.special_event > 0) && !eventInfo.checkout_date">&nbsp;&nbsp;</span>
                  <!-- End submit button -->

                  <!-- Muti-Submit button -->
                  <b-button v-if="eventInfo.member_no && vaild_member && eventInfo.id == 0 && eventInfo.org_therapist.length > 0 && (eventInfo.bookingStartTime.hours != eventInfo.bookingEndTime.hours || eventInfo.bookingStartTime.minutes != eventInfo.bookingEndTime.minutes) && !eventInfo.checkout_date" variant="primary" @click="action_auth('submit_continue')">
                    <span>{{ $t('common.button.submit') }}及繼續預約</span>
                  </b-button>
                  <!-- End submit button -->
                  
                  <!-- Check-in button -->
                  <b-button
                    variant="warning"
                    @click="action_auth('checkin')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)"
                  >
                    <span>Check-in</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)">&nbsp;&nbsp;</span>
                  <!-- End check-in button -->

                  <!-- Therapist Check-in button -->
                  <b-button
                    variant="warning"
                    @click="action_auth('therapist_checkin')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.without_therapist == '1' || eventInfo.without_therapist == '2') && eventInfo.checkin_time"
                  >
                    <span>理療師進入房間</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.without_therapist == '1' || eventInfo.without_therapist == '2') && eventInfo.checkin_time">&nbsp;&nbsp;</span>
                  <!-- End Therapist check-in button -->
                  
                  <!-- Check-out button -->
                  <b-button
                    variant="warning"
                    @click="action_auth('checkout')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.checkin_time && !eventInfo.checkout_time) && eventInfo.without_therapist == '0'"
                  >
                    <span>Check-out</span>
                  </b-button>
                  <!-- End check-out button -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="display: none">
            <button
              id="eventModal_close"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <b-button
      id="eventModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#eventModal"
      >{{ $t('common.button.confirm') }}
    </b-button>
    <!-- End of Modal for Event -->

    <!-- Confirm delete modal-->
    <div>
      <b-modal
        v-model="modalShow"
        class="text-secondary mb-0"
        hide-footer
        :title="$t('common.message.warning')"
      >
        <div>
          <div class="card-header pb-0">
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modalShow">
                <h5 class="text-delete-warning">
                  {{ $t('common.button.confirm') }}取消預約？
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-left">
                <Multiselect
                  v-model="eventInfo.cancel_reason"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="false"
                  :options="cancel_reason_list"
                  :allow-empty="false"
                  :canClear="true"
                  valueProp="id"
                  trackBy="name_tc"
                  label="name_tc"
                  placeholder="請選擇取消原因"
                />
              </div>
            </div>
            <div v-if="eventInfo.cancel_reason == 8" class="row">
              <div class="col-12 text-left">
                <CFormInput
                  v-model="eventInfo.cancel_reason_remark"
                  type="text"
                  placeholder="其他原因"
                />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div></div>
              <div>
                <b-button variant="secondary" @click="cancel_delete()">{{
                  $t('common.button.cancel')
                }}</b-button>
                &nbsp;
                <b-button
                  variant="danger"
                  @click="action_auth('confirm_delete')"
                  >{{ $t('common.button.confirm') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- End of delete modal-->

    <!-- Confirm dup modal-->
    <div>
      <b-modal
        v-model="modalShow2"
        class="text-secondary mb-0"
        hide-footer
        :title="$t('common.button.confirm')"
      >
        <div>
          <div class="card-header pb-0">
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modalShow2">
                <h5 class="text-delete-warning">
                  需要複製預約？
                </h5>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div></div>
              <div>
                <b-button variant="secondary" @click="cancel_dup()">{{
                  $t('common.button.cancel')
                }}</b-button>
                &nbsp;
                <b-button
                  variant="danger"
                  @click="confirm_dup()"
                  >{{ $t('common.button.confirm') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- End of dup modal-->

    <!-- Without Therapist -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="without_therapistoffcanvas">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title text-center">房間沒有理療師</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body text-left">
        <div>
          <template v-for="item in without_therapist_list" :key="item.id">
            <div>
              <div class="row">
                <div class="col-10 text-left">
                  <span>房號: {{ getlistitem('room', item.room) }}</span>
                  <span v-show="item.without_therapist == 1" style="color:red;">&nbsp;&nbsp;未有理療師</span>
                  <span v-show="item.without_therapist == 2" style="color:red;">&nbsp;&nbsp;有部份理療師</span>
                </div>
                <div class="col-2 text-right">
                  <b-button
                    size="sm"
                    variant="outline-success"
                    @click="booking_click(item)"
                    >
                    <span>{{ $t('common.button.view') }}</span>
                  </b-button>
                </div>
              </div>
              <div class="row">
                <span>
                  客戶: {{ item.member_no }}<span v-show="!item.nickname">-{{ item.name }}</span><span v-show="item.nickname">-{{ item.nickname }}</span>
                </span>
              </div>
              <div class="row">
                <span>Check-in時間: {{ item.checkin_date }} {{ item.checkin_time }}</span>
              </div>
              <div class="row">
                <span>理療師: {{ getlistitem('therapist', item.org_therapist) }}</span>
              </div>
            </div>
            <hr>
          </template>
        </div>
      </div>
    </div>
    <!-- End of Without Therapist -->

    <!-- Function list -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="functionlistoffcanvas">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title text-center">Booking System功能表</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body text-left">
        <div class="row">
          <div class="col-12 text-left">
            <b-button
              class="mx-1 position-relative"
              variant="outline-secondary"
              @click="advisor_mode()"
            >
              <span>會員覆查表</span>
            </b-button>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12 text-left">
            <b-button
              class="mx-1 position-relative"
              variant="outline-secondary"
              @click="MemberBlockList()"
            >
              <span>注意 / 黑名單會員</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Function list -->

    <!-- Timer -->
    <div class="offcanvas offcanvas-start offcanvas-width4" tabindex="-1" id="timeroffcanvas">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title text-center">儀器計時</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body text-left">
        <div>
          <template v-for="item in timer_list" :key="item.id">
          <div class="row">
            <div class="col-8 text-left">
              <span>
                儀器: {{ getlistitem('machine_sku', item.machine_id) }} ({{ getlistitem('room', item.room_id) }})
              </span>
            </div>
            <div class="col-4 text-right">
              <span>
                開始時間: {{ item.start_time.slice(0, 5) }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-5 text-left">
              <span>
                預計結束時間: {{ item.estimate_end_time.slice(0, 5) }}
              </span>
            </div>
            <div class="col-7 text-right">
              <span>
                距離結束時間: 
              </span>
              <span style="color:red;">
                {{ item.remain }}
              </span>
            </div>
          </div>
          <div class="row" v-if="item.latest_machine_level">
            <div class="col-5 text-left">
              <span>
                檢查度數: {{ item.latest_machine_level }}
              </span>
            </div>
            <div class="col-7 text-right" v-if="item.latest_by">
              <span>
                檢查度數同事: {{ item.latest_by }}
              </span>
            </div>
          </div>
          <hr>
        </template>
        </div>
      </div>
    </div>
    <!-- End of Timer -->

    <!-- Roster -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="rosteroffcanvas">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">Roster更表</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="back_roster()"></button>
      </div>
      <div class="offcanvas-body">
        <div class="row">
          <div class="col-3 text-left">
            <span>日期</span>
          </div>
          <div class="col-6 text-left">
            <datepicker
            v-model="business_day"
            size="sm"
            auto-apply
            input-format="yyyy-MM-dd"
            :clearable="false"
            :enable-time-picker="false"
            @update:modelValue="change_business_day()"
            />
          </div>
          <div class="col-3 text-left">
            <span v-show="roster_submitted" style="color:green;">已提交</span>
            <span v-show="!roster_submitted" style="color:red;">未提交</span>
          </div>
        </div>
        <div class="row">
          <div class="col-3 text-left">
            <span>上班同事</span>
          </div>
          <div class="col-9 text-left">
            <Multiselect
              v-model="business_day_roster"
              mode="tags"
              :close-on-select="false"
              :searchable="true"
              :create-option="false"
              :options="full_therapist_list"
              :allow-empty="false"
              valueProp="id"
              trackBy="nickname"
              label="nickname"
              @input="roster_check()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <b-form-checkbox
              v-model="default_roster"
              value="1"
              unchecked-value="0"
              @change="default_list()"
            >
            <span>預設{{ branch }}同事</span>
            </b-form-checkbox>
          </div>
        </div>
        <div class="row" v-show="roster_booked">
          <div class="col-12 text-left">
            <span style="color:red;">{{ roster_booked }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <b-button v-if="business_day_roster.length > 0" variant="success" @click="update_roster()">
              <span>{{ $t('common.button.submit') }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Roster -->

    <!-- Search Booking -->
    <div class="offcanvas offcanvas-end offcanvas-width" tabindex="-1" id="searchbookingoffcanvas">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">會員編號{{ member_no }}-Booking記錄</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="back_search()"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="booking_history.length > 0">
          <div class="row" v-show="history_today">
            <div class="col-12 text-left">
              <b-button
                v-show="history_today"
                variant="secondary"
                @click="history_today = false"
              >
                顯示全部記錄
              </b-button>
            </div>
          </div>
          <br>
          <template v-for="item in getHistoryList" :key="item.id">
            <div class="row">
              <div class="col-3 text-left">
                <span>預約日期:</span>
              </div>
              <div class="col-5 text-left">
                <span>{{ item.booking_date }}</span>
                <span style="color:red;" v-if="item.status == -1"> (已取消)</span>
              </div>
              <div class="col-4 text-right">
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="booking_click(item)"
                  >
                  <span>{{ $t('common.button.view') }}</span>
                </b-button>
                &nbsp;
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="getBookingHistory(item)"
                  v-if="role_check(17)"
                  >
                  <span>{{ $t('common.button.history') }}</span>
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>預約時間:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.start_time }}-{{ item.end_time }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>理療師:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ getlistitem('therapist', item.org_therapist) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>建立時間:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.created_at.substring(0, 10) }} {{ item.created_at.substring(11, 19) }} ({{ item.created_by_nickname }})</span>
              </div>
            </div>
            <hr>
          </template>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有Booking記錄</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Search Booking -->

    <!-- Booking History -->
    <div class="offcanvas offcanvas-end offcanvas-width2" tabindex="-1" id="historyoffcanvas">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">Booking Change Log</h5>
        <button type="button" class="btn-close text-reset" @click="back_history()"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="selected_history.length > 0">
          <template v-for="item in selected_history" :key="item.id">
            <div class="row">
              <div class="col-3 text-left">
                <span>會員編號:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.member_no }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>預約日期:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.booking_date }} ({{ item.start_time }}-{{ item.end_time }})</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>理療師:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ getlistitem('therapist', item.org_therapist) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>建立時間:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.created_at.substring(0, 10) }} {{ item.created_at.substring(11, 19) }} ({{ item.created_by_nickname }})</span>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-8 text-left">
                <b>修改內容</b>
              </div>
              <div class="col-4 text-right">
                <b>日期</b>
              </div>
            </div>
            <template v-if="item.history.length > 0" class="row">
              <template v-for="history in item.history" :key="history.id">
                <div class="row">
                  <div class="col-8 text-left">
                    <span v-show="history.from_data.nickname || history.to_data.nickname">別名: {{ history.from_data.nickname ? history.from_data.nickname+'->' : '新增' }}{{ history.to_data.nickname }}<br></span>
                    <span v-show="history.from_data.gender || history.to_data.gender">性別: {{ history.from_data.gender }}->{{ history.to_data.gender }}<br></span>
                    <span v-show="history.from_data.booking_date || history.to_data.booking_date">預約日期: {{ history.from_data.booking_date }}->{{ history.to_data.booking_date }}<br></span>
                    <span v-show="history.from_data.start_time || history.to_data.start_time">開始時間: {{ history.from_data.start_time }}->{{ history.to_data.start_time }}<br></span>
                    <span v-show="history.from_data.end_time || history.to_data.end_time">結束時間: {{ history.from_data.end_time }}->{{ history.to_data.end_time }}<br></span>
                    <span v-show="history.from_data.remark || history.to_data.remark">備註: {{ history.from_data.remark ? history.from_data.remark+'->' : '新增' }}{{ history.to_data.remark }}<br></span>
                    <span v-show="history.from_data.therapist || history.to_data.therapist">理療師: {{ getlistitem('therapist', history.from_data.therapist) }}->{{ getlistitem('therapist', history.to_data.therapist) }}<br></span>
                    <span v-show="history.to_data.recommend =='1'">推介理療師: 選擇<br></span>
                    <span v-show="history.to_data.specify =='1'">指定理療師: 選擇<br></span>
                    <span v-show="history.to_data.new_join =='1'">新會員: 選擇<br></span>
                    <span v-show="history.from_data.reconfirm || history.to_data.reconfirm">Re Confirm: {{ history.from_data.reconfirm ? history.from_data.reconfirm+'->' : '新增' }}{{ history.to_data.reconfirm }}<br></span>
                    <span v-show="history.from_data.arrival_time || history.to_data.arrival_time">到達時間: {{ history.from_data.arrival_time ? history.from_data.arrival_time+'->' : '新增' }}{{ history.to_data.arrival_time }}<br></span>
                    <span v-show="history.from_data.room || history.to_data.room">房號: {{ getlistitem('room', history.from_data.room) ? getlistitem('room', history.from_data.room)+'->' : '新增' }}{{ getlistitem('room', history.to_data.room) }}<br></span>
                    <span v-show="history.from_data.machine || history.to_data.machine">儀器機: {{ getlistitem('machine', history.from_data.machine) ? getlistitem('machine', history.from_data.machine)+'->' : '新增' }}{{ getlistitem('machine', history.to_data.machine) }}<br></span>
                    <span v-show="history.from_data.advisor || history.to_data.advisor">顧問: {{ getlistitem('advisor', history.from_data.advisor) ? getlistitem('advisor', history.from_data.advisor)+'->' : '新增' }}{{ getlistitem('advisor', history.to_data.advisor) }}<br></span>
                    <span v-show="history.from_data.nutritionist || history.to_data.nutritionist">營養師: {{ getlistitem('nutritionist', history.from_data.nutritionist) ? getlistitem('nutritionist', history.from_data.nutritionist)+'->' : '新增' }}{{ getlistitem('nutritionist', history.to_data.nutritionist) }}<br></span>
                    <span v-show="history.from_data.chinese_medicine || history.to_data.chinese_medicine">中醫師: {{ getlistitem('chinese_medicine', history.from_data.chinese_medicine) ? getlistitem('chinese_medicine', history.from_data.chinese_medicine)+'->' : '新增' }}{{ getlistitem('chinese_medicine', history.to_data.chinese_medicine) }}<br></span>
                    <span v-show="history.from_data.created_by || history.to_data.created_by">建立者: {{ getlistitem('staff', history.from_data.created_by) }}->{{ getlistitem('staff', history.to_data.created_by) }}<br></span>
                    <span v-show="history.to_data.checkin_date">CHECK IN<br></span>
                    <span v-show="history.to_data.checkout_date">CHECK OUT<br></span>
                    <span v-show="history.to_data.status == '1'">重新預約<br></span>
                    <span v-show="history.to_data.status == '-1'">取消預約<br></span>
                    <span v-show="history.to_data.booking_show == '0'">取消Show<br></span>
                    <span v-show="history.to_data.booking_show == '1'">Show<br></span>
                    <span v-show="history.to_data.deal == '0'">取消Deal<br></span>
                    <span v-show="history.to_data.deal == '1'">Deal<br></span>
                    <span v-show="history.to_data.todo == '1'">已處理<br></span>
                  </div>
                  <div class="col-4 text-right">
                    <span>{{ history.created_at.substring(0, 10) }} {{ history.created_at.substring(11, 19) }}<br>{{ history.nickname }}</span>
                  </div>
                </div>
                <hr>
              </template>
            </template>
            <div v-else class="row">
              <div class="col-12 text-center">
                <span style="color:red;">沒有修改記錄</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- End of Booking History -->

    <div class="row">
      <div class="col-2 text-center" v-if="sourceList == 'active'">
        <DxDraggable
          v-for="task in tasks"
          :key="task.text"
          :clone="true"
          :data="task"
          :group="draggingGroupName"
          :on-drag-start="dragStart"
          class="drag dx-card"
        >
          {{ task.text }}
        </DxDraggable>
      </div>
      <div class="col-2 text-center" v-if="sourceList == 'cancel'">
      </div>
      <div class="col-1 text-right">
        <b-button
          size="sm"
          :variant="timerfontcolor"
          data-bs-toggle="offcanvas"
          data-bs-target="#timeroffcanvas"
          >
            <span style="color:white;" :class="timer_list.length > 0 ? 'need_update2' : ''">{{ timer_list.length }} 個儀器正在使用中</span>
          </b-button
        >
      </div>
      <div class="col-1 text-right" >
        <b-button
          size="sm"
          variant="outline-danger"
          data-bs-toggle="offcanvas"
          data-bs-target="#without_therapistoffcanvas"
          >
            <span :class="without_therapist_list.length > 0 ? 'without_therapist' : ''">{{ without_therapist_list.length }} 間房未有理療師</span>
          </b-button
        >
        <DxCheckBox
          v-model:value="allowDragging"
          text="允許Drag & Drop"
          style="display: none"
        />
        <DxCheckBox
          v-model:value="allowAdding"
          text="允許Adding"
          style="display: none"
        />
      </div>
      <div class="col-4 text-center">
        <span v-if="sourceList == 'active'" style="color:green;">正在顯示預約列表</span>
        <span v-if="sourceList == 'cancel'" style="color:red;">正在顯示取消列表</span>
      </div>
      <div class="col-2 text-right">
        <CFormInput
          v-model="member_no"
          size="sm"
          type="text"
          @change="before_booking_check()"
          placeholder="以 電話號碼/會員編號 搜尋全部預約記錄"
        />
        <b-button
          id="searchbookingbutton"
          class="mx-1 position-relative"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#searchbookingoffcanvas"
          style="display: none"
        >
          <span>Roster</span>
        <b-button
          id="bookinghistorybutton"
          class="mx-1 position-relative"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#historyoffcanvas"
          style="display: none"
        >
          <span>Roster</span>
        </b-button>
        </b-button>
      </div>
      <div class="col-1 text-right">
        <b-button
          class="mx-1 position-relative"
          size="sm"
          variant="outline-warning"
          @click="get_api()"
          >
            <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
            <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
              <span style="color: red; font-size: 1rem !important;">!</span>
            </b-badge>
        </b-button>
      </div>
      <div class="col-1 text-right">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="changeDataSource()"
          >
            <span v-if="sourceList == 'active'">查看取消列表</span>
            <span v-if="sourceList == 'cancel'">查看預約列表</span>
          </b-button
        >
      </div>
      <!--
      <div class="col-1 text-right">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="openFullscreen()"
          >{{ $t('common.button.fullscreen') }}</b-button
        >
      </div>
      -->
    </div>
    <div class="row">
      <div class="col-12">
          <div class="dx-viewport">
            <DxScheduler
              ref="bookingScheduler"
              time-zone="Asia/Hong_Kong"
              :data-source="dataSourceList"
              :groups="groups"
              :current-date="currentDate"
              :start-day-hour="11"
              :end-day-hour="23"
              :cross-scrolling-enabled="true"
              :show-all-day-panel="false"
              :current-view="currentView"
              :views="views"
              :cell-duration="15"
              :on-appointment-click="create_event"
              @appointmentDblClick="create_event"
              time-cell-template="timeCellTemplate"
              appointment-template="show_event"
              appointment-tooltip-template="show_event_tooltip"
              @appointment-updated="drag_event"
              :adaptivity-enabled="true"
              date-serialization-format="yyyy-MM-ddTHH:mm:ss"
              :on-option-changed="optionChangeHandle"
              :use-drop-down-view-switcher="false"
              :show-current-time-indicator="false"
            >
              <DxAppointmentDragging
                :group="draggingGroupName"
                :on-remove="onAppointmentRemove"
                :on-add="onAppointmentAdd"
                
              />
              <DxEditing
                :allowAdding="allowAdding"
                :allowDeleting="true"
                :allowUpdating="true"
                :allowResizing="false"
                :allowDragging="allowDragging"
              />
              <DxResource
                :allow-multiple="false"
                :data-source="roster_list"
                field-expr="therapist"
                :display-expr="getName"
                label="Priority"
              />
              <template #show_event="{ data }">
                <div v-if="data.appointmentData.special_event > 0" class="disable-date">
                  <br>
                  <img v-if="data.appointmentData.special_event == 1" :src="'./bowl_rice.svg'" width="30" height="25"/>
                  <font-awesome-icon v-if="data.appointmentData.special_event == 2" :icon="['fas', 'user-slash']" size="lg"/>
                  <img v-if="data.appointmentData.special_event == 3" :src="'./person_chalkboard.svg'" width="30" height="25"/>            
                  <img v-if="data.appointmentData.special_event == 4" :src="'./people_group.svg'" width="30" height="25"/>
                  <font-awesome-icon v-if="data.appointmentData.special_event == 5" :icon="['fas', 'thumbtack']" size="lg"/>
                  <br>
                  <b style="font-size: 17px !important;">{{ getlistitem('special_event', data.appointmentData.special_event) }}</b><br>
                  <span style="font-size: 17px !important;">{{ data.appointmentData.remark }}</span>
                </div>
                <div v-else :class="data.appointmentData.arrival_time ? data.appointmentData.gender == 'M' ? 'genderM genderMBG' : 'genderF' : data.appointmentData.gender == 'M' ? 'genderMBG' : ''">
                  <div v-show="view_mode == 'day'">
                    <div :class="data.appointmentData.new_join == '1' ? data.appointmentData.created_from == '1' ? 'newJoin' : data.appointmentData.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''">
                      <span v-show="data.appointmentData.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                      <span v-show="data.appointmentData.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                      {{ data.appointmentData.member_no.toUpperCase() }}
                      <span v-show="data.appointmentData.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
                      <span v-show="data.appointmentData.new_join == '1'"> (新會員)</span>
                      <span class="newJoin" v-show="data.appointmentData.recommend == '1'"> (推)</span>
                      <span v-show="data.appointmentData.org_therapist.length > 1" style="color:red; font-size: 14px;"> =</span>
                    </div>
                    <span v-show="data.appointmentData.nickname">{{ data.appointmentData.nickname }}&nbsp;</span>
                    <span v-show="!data.appointmentData.nickname">{{ data.appointmentData.name }}&nbsp;</span>
                    <span v-show="data.appointmentData.member_type">({{ getlistitem('member_type', data.appointmentData.member_type) }})&nbsp;</span>
                    <br>
                    <span v-show="data.appointmentData.remark">備註: {{ data.appointmentData.remark }}<br></span>
                    <div :class="data.appointmentData.without_therapist == '1' ? 'without_therapist' : ''">
                      <span v-show="data.appointmentData.room">房號: <span>{{ getlistitem('room', data.appointmentData.room) }}</span>&nbsp;<br></span>
                    </div>
                    <span v-show="data.appointmentData.machine.length > 0">儀器機: {{ getlistitem('machine', data.appointmentData.machine) }}<br></span>
                    <div :class="data.appointmentData.checkin_date && !data.appointmentData.checkout_date && (today_date > data.appointmentData.booking_date || currentTime > data.appointmentData.end_time) ? 'without_therapist' : ''">
                      <span v-show="data.appointmentData.checkin_date && !data.appointmentData.checkout_date"><img :src="'./In.svg'" width="40" height="33"/></span>
                      <span v-show="data.appointmentData.checkout_date"><img :src="'./Out.svg'" width="40" height="33"/></span>
                    </div>
                    <span v-show="data.appointmentData.cancel_reason"><br>取消原因: {{ getlistitem('cancel_reason', data.appointmentData.cancel_reason) }}</span>
                    <span v-show="data.appointmentData.cancel_reason_remark">-{{ data.appointmentData.cancel_reason_remark }}</span>
                    <br>
                    <div v-show="data.appointmentData.block_list" style="padding:12px" :style="{ 'background-color': data.appointmentData.block_list_color}">
                      <img :src="'./triangle_exclamation.svg'" width="30" height="25"/>
                      <br>
                      <b style="color:white; white-space:break-spaces;">{{ data.appointmentData.block_list_color_desc }}</b>
                      <br>
                      <b v-show="data.appointmentData.block_list_remark" style="color:white; white-space:break-spaces;">{{ data.appointmentData.block_list_remark }}</b>
                    </div>
                  </div>
                  <div v-show="view_mode == 'agenda'">
                    <div :class="data.appointmentData.new_join == '1' ? 'newJoin' : ''">
                      <span v-show="data.appointmentData.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                      <span v-show="data.appointmentData.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                      <span>{{ data.appointmentData.member_no }}&nbsp;</span>
                      <span v-show="data.appointmentData.nickname">{{ data.appointmentData.nickname }}&nbsp;</span>
                      <span v-show="!data.appointmentData.nickname">{{ data.appointmentData.name }}&nbsp;</span>
                      <span v-show="data.appointmentData.new_join == '1'">(新會員)&nbsp;</span>
                      <span class="newJoin" v-show="data.appointmentData.recommend == '1'"> (推)&nbsp;</span>
                      <span v-show="data.appointmentData.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/>&nbsp;</span>
                      <span v-show="data.appointmentData.room">房號: <span>{{ getlistitem('room', data.appointmentData.room) }}</span>&nbsp;</span>
                      <span v-show="data.appointmentData.remark">備註: {{ data.appointmentData.remark }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template #timeCellTemplate="{ data }">
                <template v-if="data.text">
                  <div style="font-size: 16px;">
                    <span>{{ get_count_name(data) }}<br></span>
                    <span v-if="data.used_room"><span style="color:#0f6cf7;">男</span><span v-if="data.used_room_M">:{{ data.used_room_M }}&nbsp;&nbsp;</span><span style="color:#f54545;">女</span><span v-if="data.used_room_F">:{{ data.used_room_F }}</span></span><br>
                    <span v-if="data.used_room">全部:{{ data.used_room }}</span>
                  </div>
                </template>
              </template>
              <DxScrolling
                mode="virtual"
              />
            </DxScheduler>
            <DxSpeedDialAction
              v-if="sourceList == 'active' && staff_code_checked && role_check(14)"
              :on-click="showPopup"
              icon="plus"
            />
          </div>
        </div>
    </div>
    </div>
  </b-overlay>
</template>

<script>
import { DxScheduler, DxResource, DxScrolling, DxEditing, DxAppointmentDragging} from 'devextreme-vue/scheduler'
import DxCheckBox from 'devextreme-vue/check-box'
import DxSpeedDialAction from 'devextreme-vue/speed-dial-action'
import DxDraggable from 'devextreme-vue/draggable'


export default {
  components: {
    DxDraggable,
    DxScheduler,
    DxResource,
    DxScrolling,
    DxEditing,
    DxSpeedDialAction,
    DxCheckBox,
    DxAppointmentDragging,
  },
  name: 'BookingList',
  data() {
    return {
      allowed: false,
      modalShow: false,
      modalShow2: false,
      followup_mode: false,
      need_update: false,
      version: '',
      version_update: false,
      auto_update_msg: false,
      therapist_version: false,
      branch_id: '',
      staff: '',
      staff_code: '',
      staff_id: '',
      action_by: '',
      action_type: '',
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      vaild_member: false,
      role: [],
      permission: [],
      member_no: '',
      selected_history: [],
      history_today: true,
      booking_history: [],
      views: ['day'],
      view_mode: 'day',
      //currentView: 'Horizontal Grouping',
      currentView: 'day',
      groups: ['therapist'],
      draggingGroupName: 'eventGroup',
      currentDate: new Date(),
      currentDateFormat: '',
      endDateFormat: '',
      allowDragging: true,
      allowAdding: false,
      show: false,
      sourceList: 'active',
      cancel_reason_list: [],
      member_type_list: [],
      lang: '',
      default_roster: '0',
      eventAction: '',
      eventInfo: [],
      old_eventInfo: [],
      org_room_list: [],
      branch_list: [],
      room_list: [],
      machine_list: [],
      machine_sku_list: [],
      gender_list: [
        { value: 'F', name: "F" },
        { value: 'M', name: 'M' },
      ],
      reconfirm_list: [
        { value: 'None', name: '未確認' },
        { value: 'Phone', name: 'Phone' },
        { value: 'Whatsapp', name: 'Whatsapp' },
      ],
      therapist_booked: '',
      full_room: '',
      bookingDatedataSource: [],
      roster_submitted: false,
      business_day: new Date(),
      roster_booked: '',
      business_day_roster_booking: [],
      old_business_day_roster: [],
      business_day_roster: [],
      roster_list: [],
      full_therapist_list: [],
      therapist_list: [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ],
      staff_list: [],
      advisor_list: [],
      chinese_medicine_list: [],
      nutritionist_list: [],
      tasks: [
        {
          text: '特別項目',
        }
      ],
      allday: 0,
      allstaff: 0,
      special_event_list: [],
      dataSource: [],
      currentTime: '',
      today_date: '',
      count_room_list: [],
      update_sync_delete: '0',
      booking_idList: [],
      request_text: '',
      config_list: [],
      timerdialogcount: 0,
      timer_click: false,
      timer_list: [],
      timer_list_existed: false,
      timerfontcolor: 'success',
      edit_mode: false,
      toasts: [],
    }
  },
  computed: {
    dataSourceList: function () {
      const self = this
      if(self.sourceList == "active"){
        return self.dataSource.filter(function (item) {
          return item.status == 1
        })
      }else{
        return self.dataSource.filter(function (item) {
          return item.status == -1
        })
      }
    },
    getHistoryList: function () {
      const self = this
      if(self.history_today == true){
        return self.booking_history.filter(function (item) {
          return item.bookingDate == self.currentDateFormat
        })
      }
      return self.booking_history
    },
    dataGrid: function() {
      return this.$refs['bookingScheduler'].instance;
    },
    without_therapist_list: function () {
      const self = this
      var list = self.dataSource.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )
      return list.filter(function (item) {
        return item.without_therapist > 0
      })
    },
    room_list_F: function () {
      const self = this
      return self.room_list.filter(function (item) {
        return item.type == 'F'
      })
    },
    room_list_M: function () {
      const self = this
      return self.room_list.filter(function (item) {
        return item.type == 'M'
      })
    },
    timeout_list: function () {
      const self = this
      return self.timer_list.filter(function (item) {
        return item.timeout == 1
      })
    },
  },
  watch: {
    allday() {
      const self = this
      if(self.allday == 1){
        self.eventInfo.bookingStartTime = {
          hours: '11',
          minutes: '00',
          seconds: '0'
        }
        self.eventInfo.bookingEndTime = {
          hours: '22',
          minutes: '00',
          seconds: '0'
        }
      }
    },
    allstaff() {
      const self = this
      self.eventInfo.org_therapist = []
      if(self.roster_list.length > 0 ){
        self.roster_list.forEach((item) => {
          if(item.id != "9999" && item.id != "99999"){
            self.eventInfo.org_therapist.push(item.id)
          }
        })
      }
    }
  },
  async created() {
    const self = this
    await self.getrealip()
    var fd_offset = self.currentDate.getTimezoneOffset()
    var date = new Date(self.currentDate.getTime() - fd_offset * 60 * 1000)
    self.currentDateFormat = date.toISOString().split('T')[0]
    self.today_date = date.toISOString().split('T')[0]
    self.endDateFormat = self.currentDateFormat
    self.branch = this.$route.params.branch
    self.lang = localStorage.getItem('locale')
    if(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
      self.$router.push('/booking/listing_m/'+self.branch)
    }else{
      setTimeout(function () {
        document.getElementById('confirmModal_open').click()
        setTimeout(function () {
          document.getElementById('staff_code').focus()
        }, 300)
      }, 200)
      setInterval(this.getNow, 1000)
      setInterval(this.check_timer, 10000)
    }
  },
  mounted() {
    const self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
    window.Echo.channel('booking_channel')
    .listen('.booking', (e) => {
      if(self.branch_id == e.message){
        self.need_update = true
        if(self.followup_mode){
          self.auto_update_msg = true
          self.get_api()
          setTimeout(function () {
            self.auto_update_msg = false
          }, 2000)
        }
      }
    })
    window.Echo.channel('machine_channel')
    .listen('.machine_timer', (e) => {
      if(self.branch_id == e.message){
        self.get_timer()
      }
    })
  },
  methods: {
    getrealip(){
      const self = this
      self.show = true
      var branch_list = []
      return new Promise(resolve => {
        this.axios
        .post('/booking/prepare', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          branch_list = response.data.branch_list
          self.axios
          .get('https://record.ankh.com.hk/api/getip', {})
          .then(function (res) {
            const ip = res.data.ip
            branch_list.forEach((item) => {
              if(item.ip_address == ip || item.ip_address2 == ip){
                self.allowed = true
              }
            })
            self.show = false
            resolve('resolved')
          })
          .catch((error) => { 
            console.error(error)
            self.allowed = true
            self.show = false
            resolve('rejected')
          })
        }).catch((error) => { 
          console.error(error)
          self.allowed = true
          self.show = false
          resolve('rejected')
        })
      })
    },
    change_mode() {
      let route = this.$router.resolve({path: '/booking/followup/'+this.branch, target: '_blank'})
      window.open(route.href, '_blank', 'menubar=yes,status=yes,resizable=yes,width=1600,height=800')
    },
    advisor_mode() {
      let route = this.$router.resolve({path: '/booking/advisor/'+this.branch, target: '_blank'})
      window.open(route.href, '_blank', 'menubar=yes,status=yes,resizable=yes,width=1600,height=800')
    },
    MemberBlockList() {
      let route = this.$router.resolve({path: '/booking/MemberBlockList', target: '_blank'})
      window.open(route.href, '_blank', 'menubar=yes,status=yes,resizable=yes,width=1600,height=800')
    },
    check_timer: function() {
      const self = this
      var timeout = 0
      var warning = false
      var danger = false
      if(self.timer_list.length > 0){
        self.timer_list.forEach((timer) => {
          if(timer.follow.length > 0){
            timer.latest_machine_level = timer.follow[(timer.follow.length)-1].machine_level
            timer.latest_by = self.getlistitem('staff', timer.follow[(timer.follow.length)-1].created_by)
          }
          timer.timeout = 0
          const timeNow = new Date().getTime()
          const timeEnd = new Date(timer.countdown_date+ " " +timer.estimate_end_time).getTime()
          const timeDifference = timeEnd - timeNow
          const millisecondsInOneSecond = 1000
          const millisecondsInOneMinute = millisecondsInOneSecond * 60
          const remainderDifferenceInMinutes = timeDifference / millisecondsInOneMinute
          const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond
          const remainingMinutes = Math.floor(remainderDifferenceInMinutes)
          const remainingSeconds = Math.floor(remainderDifferenceInSeconds)
          var pec = 0
          var second = parseInt(timer.minutes) * 60
          var remain = Math.floor(timeDifference / millisecondsInOneSecond)
          if(second-remain > 0){
            pec = Math.floor(((second-remain) / second) * 100)
            if(pec > 75){
              danger = true
            }else if(pec > 50){
              warning = true
            }
          }
          if(timeDifference > 0){
            timer.remain = remainingSeconds + "秒"
            if(remainingMinutes > 0){
              timer.remain = remainingMinutes + "分鐘" + remainingSeconds + "秒"
            }
          }else{
            timeout = 1
            timer.remain = "已超時!"
            timer.timeout = 1
            if(self.timerdialogcount == 0 && self.timer_click == false && self.edit_mode == false){
              self.timer_click = true
              self.timer_list_existed = true
              document.getElementById('timerModal_open').click()
            }
          }
        })
        setTimeout(function () {
          if(danger == true){
          self.timerfontcolor = 'danger'
          }else if(warning == true){
            self.timerfontcolor = 'warning'
          }else{
            self.timerfontcolor = 'success'
          }
        }, 1000)
      }
      if(timeout == 0 && self.timer_list_existed == true){
        self.timerfontcolor = 'success'
        document.getElementById('timerModal_close').click()
        self.timer_list_existed = false
        self.close_timer()
      }
    },
    getNow: function() {
      const self = this
      const today = new Date();
      const time = today.getHours().toString().padStart(2, '0') + ":" + today.getMinutes().toString().padStart(2, '0')
      self.currentTime = time
      if(self.timerdialogcount > 0){
        self.timerdialogcount--
      }
    },
    close_timer(){
      const self = this
      self.timerdialogcount = 60
      self.timer_click = false
    },
    arrival_now(){
      const self = this
      self.eventInfo.arrivalTime = {
        hours: self.currentTime.substring(0, 2),
        minutes: self.currentTime.substring(3, 5),
        seconds: '00'
      }
    },
    dragStart(){
      const self = this
      self.dragmode = true
    },
    onAppointmentAdd(e) {
      const self = this
      self.booking_idList  = []
      self.update_sync_delete = '0'
      self.therapist_list = self.roster_list
      if(self.dragmode == true){
        var shours = e.itemData.startDate.substring(11, 13)
        var sminutes = e.itemData.startDate.substring(14, 16)
        var thours = e.itemData.startDate.substring(11, 13)
        var tminutes = parseInt(e.itemData.startDate.substring(14, 16)) + 30
        if(sminutes == "30"){
          thours = parseInt(thours) + 1
          tminutes = "00"
        }
        var therapist = []
        therapist.push(e.itemData.therapist)
        self.eventInfo = {
          id: 0,
          member_no: '',
          name: '',
          gender: '',
          nickname: '',
          therapist: e.itemData.therapist,
          org_therapist: therapist,
          without_therapist: 0,
          without_advisor: 0,
          advisor: [],
          nutritionist: [],
          chinese_medicine: [],
          bookingDate: self.currentDateFormat,
          bookingStartTime: {
            hours: shours,
            minutes: sminutes,
            seconds: '0'
          },
          bookingEndTime: {
            hours: thours,
            minutes: tminutes,
            seconds: '0'
          },
          arrivalTime: {
            hours: '00',
            minutes: '00',
            seconds: '0'
          },
          arrival_time: '',
          startDate: self.currentDateFormat+"T"+shours+":"+sminutes+":00",
          endDate: self.currentDateFormat+"T"+thours+":"+tminutes+":00",
          start_time: shours+":"+sminutes,
          end_time: thours+":"+tminutes,
          room: '',
          machine: [],
          new_join: '0',
          specify: '0',
          recommend: '0',
          reconfirm: 'None',
          remark: '',
          remark_call: '',
          remark_internal: '',
          member_type: '',
          booking_show: 0,
          deal: 0,
          special_event: 1,
          checkin_date: '',
          checkin_time: '',
          checkout_date: '',
          checkout_time: null,
          created_from: 1,
          created_by: '',
          status: 1,
          block_list: false,
          block_list_remark: '',
          block_list_color: '',
          block_list_color_desc: '',
        }
        self.dataSource.push(self.eventInfo)
        self.refreshDataGrid()
        self.eventAction = "create"
        document.getElementById('eventModal_open').click()
        self.old_eventInfo = {
          id: self.eventInfo.id,
          member_no: self.eventInfo.member_no,
          name: self.eventInfo.name,
          nickname: self.eventInfo.nickname,
          gender: self.eventInfo.gender,
          therapist: self.eventInfo.therapist,
          org_therapist: self.eventInfo.org_therapist,
          without_therapist: self.eventInfo.without_therapist,
          without_advisor: self.eventInfo.without_advisor,
          advisor: self.eventInfo.advisor,
          nutritionist: self.eventInfo.nutritionist,
          chinese_medicine: self.eventInfo.chinese_medicine,
          bookingDate: self.eventInfo.bookingDate,
          start_time: self.eventInfo.start_time,
          bookingStartTime: self.eventInfo.bookingStartTime,
          end_time: self.eventInfo.end_time,
          bookingEndTime: self.eventInfo.bookingEndTime,
          arrivalTime: self.eventInfo.arrivalTime,
          arrival_time: self.eventInfo.arrival_time,
          startDate: self.eventInfo.startDate,
          endDate: self.eventInfo.endDate,
          room: self.eventInfo.room,
          machine: self.eventInfo.machine,
          new_join: self.eventInfo.new_join,
          specify: self.eventInfo.specify,
          recommend: self.eventInfo.recommend,
          reconfirm: self.eventInfo.reconfirm,
          remark: self.eventInfo.remark,
          remark_call: self.eventInfo.remark_call,
          remark_internal: self.eventInfo.remark_internal,
          member_type: self.eventInfo.member_type,
          booking_show: self.eventInfo.booking_show,
          deal: self.eventInfo.deal,
          cancel_reason: self.eventInfo.cancel_reason,
          cancel_reason_remark: self.eventInfo.cancel_reason_remark,
          special_event: self.eventInfo.special_event,
          checkin_date: self.eventInfo.checkin_date,
          checkin_time: self.eventInfo.checkin_time,
          checkout_date: self.eventInfo.checkout_date,
          checkout_time: self.eventInfo.checkout_time,
          created_from: self.eventInfo.created_from,
          created_by: self.eventInfo.created_by,
          status: self.eventInfo.status,
          block_list: self.eventInfo.block_list,
          block_list_remark: self.eventInfo.block_list_remark,
          block_list_color: self.eventInfo.block_list_color,
          block_list_color_desc: self.eventInfo.block_list_color_desc,
        }
      }
    },
    onAppointmentRemove(e) {
      console.log(e)
    },
    refreshDataGrid() {
      const self = this
      self.dataGrid.getDataSource().reload()
      setTimeout(function () {
        self.allowAdding = true
      }, 100)
      setTimeout(function () {
        self.allowAdding = false
      }, 200)
      setTimeout(function () {
        var schedulerScrollable = self.dataGrid._workSpace.getScrollable()
        schedulerScrollable.scrollTo(self.position)
        self.show = false
      }, 300)
    },
    create_event(e) {
      e.cancel = true
      const self = this
      self.edit_mode = true
      self.dragmode = false
      self.eventAction = "edit"
      self.eventInfo = e.appointmentData
      self.therapist_booked = ''
      self.full_room = ''
      self.booking_idList  = []
      self.update_sync_delete = '0'
      self.old_eventInfo = {
        id: self.eventInfo.id,
        member_no: self.eventInfo.member_no,
        name: self.eventInfo.name,
        nickname: self.eventInfo.nickname,
        gender: self.eventInfo.gender,
        therapist: self.eventInfo.therapist,
        org_therapist: self.eventInfo.org_therapist,
        without_therapist: self.eventInfo.without_therapist,
        without_advisor: self.eventInfo.without_advisor,
        advisor: self.eventInfo.advisor,
        nutritionist: self.eventInfo.nutritionist,
        chinese_medicine: self.eventInfo.chinese_medicine,
        bookingDate: self.eventInfo.bookingDate,
        bookingStartTime: self.eventInfo.bookingStartTime,
        bookingEndTime: self.eventInfo.bookingEndTime,
        arrivalTime: self.eventInfo.arrivalTime,
        arrival_time: self.eventInfo.arrival_time,
        startDate: self.eventInfo.startDate,
        endDate: self.eventInfo.endDate,
        start_time: self.eventInfo.start_time,
        end_time: self.eventInfo.end_time,
        room: self.eventInfo.room,
        machine: self.eventInfo.machine,
        new_join: self.eventInfo.new_join,
        specify: self.eventInfo.specify,
        recommend: self.eventInfo.recommend,
        reconfirm: self.eventInfo.reconfirm,
        remark: self.eventInfo.remark,
        remark_call: self.eventInfo.remark_call,
        remark_internal: self.eventInfo.remark_internal,
        member_type: self.eventInfo.member_type,
        booking_show: self.eventInfo.booking_show,
        deal: self.eventInfo.deal,
        cancel_reason: self.eventInfo.cancel_reason,
        cancel_reason_remark: self.eventInfo.cancel_reason_remark,
        special_event: self.eventInfo.special_event,
        checkin_date: self.eventInfo.checkin_date,
        checkin_time: self.eventInfo.checkin_time,
        checkout_date: self.eventInfo.checkout_date,
        checkout_time: self.eventInfo.checkout_time,
        created_from: self.eventInfo.created_from,
        created_by: self.eventInfo.created_by,
        status: self.eventInfo.status,
        block_list: self.eventInfo.block_list,
        block_list_remark: self.eventInfo.block_list_remark,
        block_list_color: self.eventInfo.block_list_color,
        block_list_color_desc: self.eventInfo.block_list_color_desc,
      }
      setTimeout(function () {
        document.getElementById('eventModal_open').click()
      }, 300)
      if(self.eventInfo.member_no){
        self.member_check(self.eventInfo)
      }
      self.room_check()
    },
    therapist_check(){
      const self = this
      var dataSource = []
      var therapist_nameList = []
      self.booking_idList  = []
      self.update_sync_delete = '0'
      self.therapist_booked = ''
      setTimeout(function () {
        if(self.eventInfo.org_therapist.length > 0 && self.eventInfo.start_time){
          self.eventInfo.org_therapist.forEach((therapist) => {
            if(therapist != "9999" && therapist != "99999"){
              dataSource = self.bookingDatedataSource.filter(function (item) {
                return item.id != self.eventInfo.id && item.therapist == therapist && item.status == 1
              })
              dataSource.forEach((booking) => {
                if((self.eventInfo.start_time >= booking.start_time && self.eventInfo.end_time <= booking.end_time) || (self.eventInfo.end_time > booking.end_time && self.eventInfo.start_time < booking.end_time) || (booking.start_time > self.eventInfo.start_time && self.eventInfo.end_time > booking.start_time)){
                  self.booking_idList.push(booking.id)
                  therapist_nameList.push(therapist)
                }
              })
            }
          })
          if(therapist_nameList.length > 0){
            self.therapist_booked = self.getlistitem('therapist', therapist_nameList)
          }
        }
      }, 500)
    },
    room_check() {
      const self = this
      self.full_room = ''
      self.room_list = JSON.parse(JSON.stringify(self.org_room_list))
      var check_dup = self.bookingDatedataSource.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )
      var dataSource = check_dup.filter(function (item) {
        return item.id != self.eventInfo.id
      })
      dataSource.forEach((item) => {
        if((self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)){
          if(item.room){
            var index = self.room_list.findIndex(el => el.id === item.room)
            if(index >= 0){
              var position = self.room_list[self.room_list.findIndex(el => el.id === item.room)].room.search("已使用")
              if(position == -1){
                self.room_list[self.room_list.findIndex(el => el.id === item.room)].room = self.room_list[self.room_list.findIndex(el => el.id === item.room)].room + "-已使用"
              }
            }
          }
        }
      })
      setTimeout(function () {
        if(self.eventInfo.gender){
          if(self.eventInfo.gender == 'M'){
            var used_room_M = dataSource.filter(function (item) {
              return item.special_event == 0 && item.status > 0 && ((self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)) && item.gender == 'M' && item.therapist != '9999' && item.therapist != '99999'
            })
            if(used_room_M.length >= self.room_list_M.length){
              self.full_room = '男賓房間數量不足'
            }
          }else if(self.eventInfo.gender == 'F'){
            var used_room_F = dataSource.filter(function (item) {
              return item.special_event == 0 && item.status > 0 && ((self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)) && item.gender == 'F' && item.therapist != '9999' && item.therapist != '99999'
            })
            if(used_room_F.length >= self.room_list_F.length){
              self.full_room = '女賓房間數量不足'
            }
          }
        }
      }, 500)
    },
    back_history() {
      const self = this
      self.selected_history = []
      document.getElementById('searchbookingbutton').click()
    },
    back_search() {
      const self = this
      self.member_no = ''
    },
    back_roster() {
      const self = this
      self.business_day_roster = self.old_business_day_roster
    },  
    booking_click(item) {
      const self = this
      self.edit_mode = true
      self.dragmode = false
      if(item.id > 0){
        self.eventAction = "edit"
      }else{
        self.eventAction = "create"
      }
      self.eventInfo = item
      self.old_eventInfo = {
        id: self.eventInfo.id,
        member_no: self.eventInfo.member_no,
        name: self.eventInfo.name,
        nickname: self.eventInfo.nickname,
        gender: self.eventInfo.gender,
        therapist: self.eventInfo.therapist,
        org_therapist: self.eventInfo.org_therapist,
        without_therapist: self.eventInfo.without_therapist,
        without_advisor: self.eventInfo.without_advisor,
        advisor: self.eventInfo.advisor,
        nutritionist: self.eventInfo.nutritionist,
        chinese_medicine: self.eventInfo.chinese_medicine,
        bookingDate: self.eventInfo.bookingDate,
        bookingStartTime: self.eventInfo.bookingStartTime,
        bookingEndTime: self.eventInfo.bookingEndTime,
        arrivalTime: self.eventInfo.arrivalTime,
        arrival_time: self.eventInfo.arrival_time,
        startDate: self.eventInfo.startDate,
        endDate: self.eventInfo.endDate,
        start_time: self.eventInfo.start_time,
        end_time: self.eventInfo.end_time,
        room: self.eventInfo.room,
        machine: self.eventInfo.machine,
        new_join: self.eventInfo.new_join,
        specify: self.eventInfo.specify,
        recommend: self.eventInfo.recommend,
        reconfirm: self.eventInfo.reconfirm,
        remark: self.eventInfo.remark,
        remark_call: self.eventInfo.remark_call,
        remark_internal: self.eventInfo.remark_internal,
        member_type: self.eventInfo.member_type,
        booking_show: self.eventInfo.booking_show,
        deal: self.eventInfo.deal,
        cancel_reason: self.eventInfo.cancel_reason,
        cancel_reason_remark: self.eventInfo.cancel_reason_remark,
        special_event: self.eventInfo.special_event,
        checkin_date: self.eventInfo.checkin_date,
        checkin_time: self.eventInfo.checkin_time,
        checkout_date: self.eventInfo.checkout_date,
        checkout_time: self.eventInfo.checkout_time,
        created_from: self.eventInfo.created_from,
        created_by: self.eventInfo.created_by,
        status: self.eventInfo.status,
        block_list: self.eventInfo.block_list,
        block_list_remark: self.eventInfo.block_list_remark,
        block_list_color: self.eventInfo.block_list_color,
        block_list_color_desc: self.eventInfo.block_list_color_desc,
      }
      setTimeout(function () {
        document.getElementById('eventModal_open').click()
      }, 200)
      if(self.eventInfo.member_no){
        self.member_check(self.eventInfo)
      }
    },
    drag_event(e) {
      const self = this
      self.therapist_list = self.roster_list
      if(e.appointmentData.checkin_date || e.appointmentData.checkout_date){
        self.get_api()
      }else{
        var org_therapist = []
        var list = self.dataSource.filter(function (item) {
          return item.id == e.appointmentData.id
        })
        list.forEach((item) => {
          var index = org_therapist.findIndex(el => el == item.therapist)
          if(index == "-1"){
            org_therapist.push(item.therapist)
          }
        })
        e.appointmentData.org_therapist = org_therapist
        e.appointmentData.bookingDate = e.appointmentData.startDate.substring(0, 10)
        e.appointmentData.bookingStartTime.hours = e.appointmentData.startDate.substring(11, 13)
        e.appointmentData.bookingStartTime.minutes = e.appointmentData.startDate.substring(14, 16)
        e.appointmentData.bookingEndTime.hours = e.appointmentData.endDate.substring(11, 13)
        e.appointmentData.bookingEndTime.minutes = e.appointmentData.endDate.substring(14, 16)
        self.eventInfo = e.appointmentData
        self.action_auth('drag_event')
      }
    }, 
    showPopup() {
      const self = this
      self.action_by = ''
      self.edit_mode = true
      self.allday = 0
      self.allstaff = 0
      self.dragmode = false
      self.therapist_booked = ''
      self.full_room = ''
        self.therapist_list = self.roster_list
        self.eventInfo = {
          id: 0,
          member_no: '',
          name: '',
          gender: '',
          nickname: '',
          therapist: 0,
          org_therapist: [],
          without_therapist: 0,
          without_advisor: 0,
          advisor: [],
          nutritionist: [],
          chinese_medicine: [],
          bookingDate: self.currentDateFormat,
          bookingStartTime: {
            hours: '11',
            minutes: '00',
            seconds: '0'
          },
          bookingEndTime: {
            hours: '11',
            minutes: '30',
            seconds: '0'
          },
          arrivalTime: {
            hours: '00',
            minutes: '00',
            seconds: '0'
          },
          arrival_time: '',
          startDate: '',
          endDate: '',
          start_time: '11:00',
          end_time: '11:30',
          room: '',
          machine: [],
          new_join: '0',
          specify: '0',
          recommend: '0',
          reconfirm: 'None',
          remark: '',
          remark_call: '',
          remark_internal: '',
          member_type: '',
          booking_show: 0,
          deal: 0,
          cancel_reason: '',
          cancel_reason_remark: '',
          special_event: 0,
          checkin_date: '',
          checkin_time: '',
          checkout_date: '',
          checkout_time: null,
          created_from: 1,
          created_by: '',
          status: 1,
          block_list: false,
          block_list_remark: '',
          block_list_color: '',
          block_list_color_desc: '',
        }
      self.eventAction = "create"
      self.room_check()
      document.getElementById('eventModal_open').click()
    },
    back() {
      const self = this
      self.edit_mode = false
      var index = self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)
      if(index >= 0){
        self.old_eventInfo.bookingStartTime = {
          hours: self.old_eventInfo.start_time.substring(0, 2),
          minutes: self.old_eventInfo.start_time.substring(3, 5),
          seconds: '00'
        }
        self.old_eventInfo.bookingEndTime = {
          hours: self.old_eventInfo.end_time.substring(0, 2),
          minutes: self.old_eventInfo.end_time.substring(3, 5),
          seconds: '00'
        }
        self.dataSource[self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)] = self.old_eventInfo
      }
      if(self.dataSource.length > 0){
        if(self.dataSource[self.dataSource.length-1].id == 0){
          self.dataSource.pop()
        }
      }
      self.room_list = JSON.parse(JSON.stringify(self.org_room_list))
      document.getElementById('eventModal_close').click()
      if(self.action_type == 'submit_continue'){
        self.get_api()
      }
      self.action_type = ''
    },
    submit() {
      const self = this
      if (self.eventInfo.bookingDate.length != 10) {
        var fd_offset = self.eventInfo.bookingDate.getTimezoneOffset()
        var date = new Date(self.eventInfo.bookingDate.getTime() - fd_offset * 60 * 1000)
        self.eventInfo.bookingDate = date.toISOString().split('T')[0]
      }
      self.eventInfo.startDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.endDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.arrival_time = self.eventInfo.arrivalTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.arrivalTime.minutes.toString().padStart(2, '0')
      self.eventInfo.status = 1
      self.update_api()
      document.getElementById('eventModal_close').click()
    },
    cancel_booking() {
      const self = this
      self.back()
      self.modalShow = true
    }, 
    confirm_delete() {
      const self = this
      self.eventInfo.status = -1
      self.update_api()
      self.modalShow = false
      document.getElementById('eventModal_close').click()
      setTimeout(function () {
        self.modalShow2 = true
      }, 1000)
    },
    cancel_delete() {
      const self = this
      self.modalShow = false
    },
    cancel_dup() {
      const self = this
      self.modalShow2 = false
    },
    confirm_dup() {
      const self = this
      self.modalShow2 = false
      self.eventInfo.id = 0
      self.eventInfo.status = 1
      self.eventInfo.cancel_reason = ''
      self.eventInfo.cancel_reason_remark = ''
      self.eventInfo.arrivalTime = {
        hours: '00',
        minutes: '00',
        seconds: '0'
      }
      self.eventInfo.room = ''
      self.booking_click(self.eventInfo)
    },
    initialize() {
      const self = this
      self.special_event_list = [
        {
          id: 1,
          event: '午膳'
        },
        {
          id: 2,
          event: '休假'
        },
        {
          id: 3,
          event: '培訓'
        },
        {
          id: 4,
          event: '會議'
        },
        {
          id: 5,
          event: '其他'
        }
      ]
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.org_room_list = JSON.parse(JSON.stringify(response.data.room_list))
        self.room_list = JSON.parse(JSON.stringify(response.data.room_list))
        self.machine_list = JSON.parse(JSON.stringify(response.data.machine_list))
        self.machine_sku_list = JSON.parse(JSON.stringify(response.data.machine_sku_list))
        self.branch_list = response.data.branch_list
        self.cancel_reason_list = response.data.cancel_reason_list
        self.member_type_list = response.data.member_type_list
        var search_branch = response.data.branch_list.filter(function (item) {
          return item.branch == self.branch
        })
        if(search_branch){
          self.branch_id = search_branch[0].id
        }
        self.staff_list = response.data.staff_list
        var user_role_list = response.data.user_role_list
        self.config_list = response.data.config_list
        self.staff_list.forEach((item) => {
          item.text = item.nickname
          if(item.active == 1){
            var therapist = user_role_list.filter(function (role) {
              return item.id == role.parent_id && role.role == 4 && role.status == 1
            })
            if(therapist.length > 0){
              self.full_therapist_list.push(item)
            }
            if(item.shop.includes(self.branch_id) && user_role_list.length > 0){
              var advisor = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 3 && role.status == 1
              })
              if(advisor.length > 0){
                self.advisor_list.push(item)
              }
              var chinese_medicine = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 7 && role.status == 1
              })
              if(chinese_medicine.length > 0){
                self.chinese_medicine_list.push(item)
              }
              var nutritionist = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 12 && role.status == 1
              })
              if(nutritionist.length > 0){
                self.nutritionist_list.push(item)
              }
            }
          }
        })
        self.change_business_day()
        setTimeout(function () {
          self.get_api()
        }, 200)
      })
    },
    get_api() {
      const self = this
      self.show = true
      var schedulerScrollable = self.dataGrid._workSpace.getScrollable()
      self.position = schedulerScrollable.scrollOffset()
      self.dataSource = []
      self.roster_list = [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      if(self.staff != 'all'){
        self.roster_list = []
      }
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', self.currentDateFormat)
      this.formData.append('end_booking_date', self.endDateFormat)
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.need_update = false
        if(self.version){
          if(self.version != response.data.version){
            self.version_update = true
            setTimeout(function () {
              self.reload()
            }, 5000)
          }
        }else{
          self.version_update = false
          self.version = response.data.version
        }
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            if(self.staff == 'all' || item.id == self.staff_id){
              item.text = item.nickname
              item.name = item.nickname
              item.color = '#ededed'
              self.roster_list.push(item)
            }
          })
          self.therapist_list = self.roster_list
        }
        self.dataSource = response.data.data
        self.bookingDatedataSource = response.data.data
        if(self.dataSource.length > 0 ){
          self.dataSource.forEach((item, idx, array) => {
            if(item.name == '找不到相關會員編號' && item.created_from != '1'){
              self.member_check(item)
            }
            item.bookingDate = item.booking_date
            item.startDate = item.bookingDate+'T'+item.start_time+':00'
            item.endDate = item.bookingDate+'T'+item.end_time+':00'
            item.bookingStartTime = {
              hours: item.start_time.substring(0, 2),
              minutes: item.start_time.substring(3, 5),
              seconds: '00'
            }
            item.bookingEndTime = {
              hours: item.end_time.substring(0, 2),
              minutes: item.end_time.substring(3, 5),
              seconds: '00'
            }
            if(item.arrival_time){
              item.arrivalTime = {
                hours: item.arrival_time.substring(0, 2),
                minutes: item.arrival_time.substring(3, 5),
                seconds: '00'
              }
            }else{
              item.arrivalTime = {
                hours: '00',
                minutes: '00',
                seconds: '0'
              }
            }

            // Advisor array
            var advisor = []
            if(item.advisor){
              var advisor_re = item.advisor
              advisor_re = advisor_re.replace('[', '')
              advisor_re = advisor_re.replace(']', '')
              advisor_re = advisor_re.replace('"', '')
              advisor = advisor_re.split(',')
            }
            item.advisor = advisor

            // Chinese Medicine array
            var chinese_medicine = []
            if(item.chinese_medicine){
              var chinese_medicine_re = item.chinese_medicine
              chinese_medicine_re = chinese_medicine_re.replace('[', '')
              chinese_medicine_re = chinese_medicine_re.replace(']', '')
              chinese_medicine_re = chinese_medicine_re.replace('"', '')
              chinese_medicine = chinese_medicine_re.split(',')
            }
            item.chinese_medicine = chinese_medicine

            // Nutritionist array
            var nutritionist = []
            if(item.nutritionist){
              var nutritionist_re = item.nutritionist
              nutritionist_re = nutritionist_re.replace('[', '')
              nutritionist_re = nutritionist_re.replace(']', '')
              nutritionist_re = nutritionist_re.replace('"', '')
              nutritionist = nutritionist_re.split(',')
            }
            item.nutritionist = nutritionist

            // Machine array
            var machine = []
            if(item.machine){
              var machine_re = item.machine
              machine_re = machine_re.replace('[', '')
              machine_re = machine_re.replace(']', '')
              machine_re = machine_re.replace('"', '')
              machine = machine_re.split(',')
            }
            item.machine = machine

            item.without_therapist = item.without_therapist.toString()
            item.without_advisor = item.without_advisor.toString()
    
            if (idx === array.length - 1){
              setTimeout(function () {
                self.count_room_bytime()
                self.get_timer()
              }, 200)
            }
          })
        }else{
          setTimeout(function () {
            self.refreshDataGrid()
          }, 300)
        }
      })
    },
    get_timer(){
      const self = this
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.axios
      .post('https://center.ankh.com.hk/api/timer/list', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiNTdlMmQ2MjFmYWFlM2Q3MWI4MDg4NWIyZjk3ODNjOTY1YjA4ZDRkMWM5ZDJjNjg2MDVmNjAxYmFjNjRhMjY3YzExYWY0Zjg5NTU3ZDg0ZjIiLCJpYXQiOjE2ODY1MzUwNDAuMzA5MjE3LCJuYmYiOjE2ODY1MzUwNDAuMzA5MjIzLCJleHAiOjE4NDQzODc4NDAuMjk2ODI0LCJzdWIiOiIzMTYiLCJzY29wZXMiOltdfQ.lLKJzzAYQl01mg7VuTsPrsJg1iXlKCpzWRR5xhp4etsuKK80NJga1LUZCjWDdCc48BlcQY_Iqh1bOHRMb9pCFgaSQeopSBdcEon3s7U4294Znkt620ujU9OmAr9dhEXRRkblFdwqsR4XjC0pWtX9ILOltvyW9Xmnssy3XbxCQah95kvL4sAF75ro3C7IsonqjxgE0HrkxlpHxgWgFaiktzed9GyUxyToBqWEv9nvmtJLJquebko1NRIKuwZplBnnycnk-HM_IN_HsmaBsa8vmDn3B7bvITdTBTH6xDV7HOrB38ioBpjNDPZggHDauES6esgA0QqBOZfGY3e8JWiWD08cywJVPU97YnUvP13l7sSom66u5krBQCnH9dNrPg2YjdWoPKoY_m8pO2TjcI92ETXlw6zTaFme_wbeluO487qFBKkrfcquUd2DU0IALWM6hzdyn67tYZvts7DN-EOe9G7jUZ0tgGfhBRCFAIfHZ2g22FN8-hQv_TobYG8Q9VxJ6jX0faP-xRkN3B6Y2gEqym80a9YaXyS3jRlw0HCV05LWsmi_zXButpF7ygszj78ZyEcC8cXa3GAgQIRx2pnoo2Hi6vmxRwfpjS2ro3W3iKvBG4KpBKuhbqFe64KLoH_xZeLhx4GIjJhQFSHhbJlMKf_Q6l3vw322hi0GjHU42SE',
        },
      })
      .then(function (response) {
        self.timer_list = response.data.timer_list.filter(function (item) {
          return item.start_time && item.end_time == null
        })
        self.check_timer()
      })
    },
    update_api() {
      const self = this
      self.edit_mode = false
      var schedulerScrollable = self.dataGrid._workSpace.getScrollable()
      self.position = schedulerScrollable.scrollOffset()
      if(self.update_sync_delete == '1'){
        self.eventInfo.booking_idList = self.booking_idList
      }
      self.show = true
      this.axios
        .post('/booking/update', {
          data: self.eventInfo,
          branch: self.branch,
          staff_id: self.staff_id,
          action_by: self.action_by,
        })
        .then(function () {
          if(self.action_type != 'submit_continue'){
            self.action_by = ''
            self.eventAction = "edit"
            self.room_list = JSON.parse(JSON.stringify(self.org_room_list))
            self.get_api()
          }else{
            self.toasts.push({
              cus_class: 'toast-success',
              data: self.eventInfo,
              content: '預約成功!請選擇下一個預約日子'
            })
            self.show = false
            self.eventInfo.id = 0
            self.eventInfo.therapist = 0
            self.eventInfo.org_therapist = []
            document.getElementById('eventModal_open').click()
          }
        })
    },
    action_request(){
      document.getElementById('requestModal_open').click()
    },
    update_request(){
      const self = this
      self.show = true
      this.axios
      .post('/booking/request', {
        data: self.eventInfo,
        action_by: self.action_by,
        request_text: self.request_text,
      })
      .then(function () {
        self.show = false
        self.request_text = ''
      })
      .catch((error) => { 
        console.error(error)
        self.show = false
      })
    },
    update_roster() {
      const self = this
      self.show = true
      var business_day = self.business_day
      if (self.business_day.length != 10) {
        var fd_offset = self.business_day.getTimezoneOffset()
        var date = new Date(self.business_day.getTime() - fd_offset * 60 * 1000)
        business_day = date.toISOString().split('T')[0]
      }
      this.axios
        .post('staff/roster/update', {
          business_day_roster: self.business_day_roster,
          business_day: business_day,
          branch: self.branch,
          staff_id: self.staff_id,
        })
        .then(function () {
          self.show = false
          self.change_business_day()
          if(business_day == self.currentDateFormat){
            self.get_api()
          }
        })
    },
    member_check(info) {
      const self = this
      if (info.member_no) {
        self.vaild_member = true
        //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: info.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            info.member_no = response.data.member.memberId
            info.name = response.data.member.cardName
            if(!info.gender){
              info.gender = response.data.member.gender
            }
          }else{
            self.vaild_member = false
            info.name = "找不到相關會員編號"
          }
        })
      }
    },
    getlistitem(list, id) {
      const self = this
      var nickname = ""
      if(list == "special_event"){
        var selected = self.special_event_list.find(i => i.id == id)
        if(selected){
          return selected.event
        }
      }else if(list == "room"){
        selected = self.room_list.find(i => i.id == id)
        if(selected){
          return selected.room
        }
      }else if(list == "machine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((machine, index) => {
            selected = self.machine_list.find(i => i.id == machine)
            if(selected){
              if(index == 0){
                nickname = selected.machine
              }else{
                nickname += ', '+selected.machine
              }
            }
          })
          return nickname
        }
      }else if(list == "machine_sku"){
        selected = self.machine_sku_list.find(i => i.id == id)
        if(selected){
          return selected.sku
        }
      }else if(list == "therapist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((therapist, index) => {
            selected = self.full_therapist_list.find(i => i.id == therapist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "advisor"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((advisor, index) => {
            selected = self.advisor_list.find(i => i.id == advisor)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "nutritionist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((nutritionist, index) => {
            selected = self.nutritionist_list.find(i => i.id == nutritionist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "chinese_medicine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((chinese_medicine, index) => {
            selected = self.chinese_medicine_list.find(i => i.id == chinese_medicine)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "staff"){
        if(id){
          selected = self.staff_list.find(i => i.id == id)
          if(selected){
            nickname = selected.nickname
          }
          return nickname
        }
      }else if(list == "cancel_reason"){
        selected = self.cancel_reason_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }else if(list == "member_type"){
        selected = self.member_type_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }
    },
    openFullscreen() {
      var elem = document.getElementById('fullscreen')
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
      }
    },
    changeDataSource() {
      const self = this
      if(self.sourceList == "active"){
        self.sourceList = "cancel"
        //self.currentView = "agenda"
      }else{
        self.sourceList = "active"
        //self.currentView = "day"
      }
    },
    optionChangeHandle(args) {
      const self = this
      if (args.name === 'currentDate') {
        var fd_offset = args.value.getTimezoneOffset()
        var date = new Date(args.value.getTime() - fd_offset * 60 * 1000)
        self.currentDateFormat = date.toISOString().split('T')[0]
        self.business_day = date.toISOString().split('T')[0]
        if(self.view_mode == 'day'){
          self.endDateFormat = self.currentDateFormat
        }else{
          var ctoday = new Date(self.currentDateFormat)
          var ctomorrow = new Date(ctoday)
          ctomorrow.setDate(ctoday.getDate()+6)
          ctomorrow.toLocaleDateString()
          var cendfd_offset = ctomorrow.getTimezoneOffset()
          var cenddate = new Date(ctomorrow.getTime() - cendfd_offset * 60 * 1000)
          self.endDateFormat = cenddate.toISOString().split('T')[0]
        }
        self.get_api()
        self.change_business_day()
        if(self.eventAction == "create"){
          self.eventInfo.bookingDate = self.currentDateFormat
        }
      }
      if (args.name === 'currentView') {
        self.view_mode = args.value
        if(args.value == 'day'){
          self.endDateFormat = self.currentDateFormat
        }else{
          var today = new Date(self.currentDateFormat)
          var tomorrow = new Date(today)
          tomorrow.setDate(today.getDate()+6)
          tomorrow.toLocaleDateString()
          var endfd_offset = tomorrow.getTimezoneOffset()
          var enddate = new Date(tomorrow.getTime() - endfd_offset * 60 * 1000)
          self.endDateFormat = enddate.toISOString().split('T')[0]
        }
        self.get_api()
      }
    },
    therapistOption(type) {
      const self = this
      if(type == 'new_join'){
        self.eventInfo.specify = '0'
        self.eventInfo.recommend = '0'
      }else if(type == 'specify'){
        self.eventInfo.new_join = '0'
        self.eventInfo.recommend = '0'
      }else if(type == 'recommend'){
        self.eventInfo.new_join = '0'
        self.eventInfo.specify = '0'
      }
    },
    checkin() {
      const self = this
      var today = new Date()
      var fd_offset = today.getTimezoneOffset()
      var date = new Date(today.getTime() - fd_offset * 60 * 1000)
      self.eventInfo.checkin_date = date.toISOString().split('T')[0]
      self.eventInfo.checkin_time = date.toISOString().split('T')[1].substring(0, 8)
      self.submit()
    },
    checkout() {
      const self = this
      var today = new Date()
      var fd_offset = today.getTimezoneOffset()
      var date = new Date(today.getTime() - fd_offset * 60 * 1000)
      self.eventInfo.checkout_date = date.toISOString().split('T')[0]
      self.eventInfo.checkout_time = date.toISOString().split('T')[1].substring(0, 8)
      self.submit()
    },
    action_auth(type) {
      const self = this
      //document.getElementById('confirmModal_close').style = 'inline'
      self.action_type = type
      if((type == 'submit_continue' || type == 'submit') && self.action_by){
        self.submit()
        console.log('Continue')
      }else{
        self.staff_code = ''
        setTimeout(function () {
          document.getElementById('confirmModal_open').click()
          setTimeout(function () {
            document.getElementById('staff_code').focus()
          }, 500)
        }, 300)
      }
    },
    role_check(role) {
      const self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    staff_code_check() {
      const self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.role = response.data.role
                var view_role = self.role.findIndex(el => el == '15')
                var ip_role = self.role.findIndex(el => el == '20')
                var therapist_role = self.role.findIndex(el => el == '4')
                if(view_role >= 0){
                  if(self.allowed == true || ip_role >= 0){
                    if(therapist_role >= 0){
                      document.getElementById('confirmModal_close').click()
                      self.therapist_version = true
                      setTimeout(function () {
                        self.$router.push('/booking/listing_m/'+self.branch)
                      }, 4000)
                    }else{
                      self.vaild_staff_code = true
                      self.permission = response.data.permission
                      var index = self.role.findIndex(el => el == '14')
                      if(index >= 0){
                        self.staff = 'all'
                      }else{
                        self.staff = self.staff_id
                      }
                      document.getElementById('confirmModal_close').click()
                      self.staff_code_checked = true
                      self.show = true
                      self.initialize()
                      var readonly = self.role.findIndex(el => el == '29')
                      if(readonly >= 0){
                        self.$router.push('/booking/listing_m/'+self.branch)
                      }
                    }
                  }else{
                    self.vaild_staff_code = false
                  }
                }else{
                  self.vaild_staff_code = false
                }
              }else {
                self.vaild_staff_code = false
              }
            }else{
              if (staff_info.length > 0) {
                self.action_by = staff_info[0]['id']
                document.getElementById('confirmModal_close').click()
                if(self.action_type == 'submit' || self.action_type == 'drag_event' || self.action_type == 'submit_continue'){
                  self.submit()
                }else if(self.action_type == 'checkin'){
                  self.checkin()
                }else if(self.action_type == 'checkout'){
                  self.checkout()
                }else if(self.action_type == 'confirm_delete'){
                  self.confirm_delete()
                }else if(self.action_type == 'therapist_checkin'){
                  self.eventInfo.without_therapist = "0"
                  self.submit()
                }else if(self.action_type == 'request'){
                  self.update_request()
                }
                self.vaild_staff_code = true
              }else {
                self.vaild_staff_code = false
              }
            }
          })
      }
      document.getElementById('staff_code').blur()
    },
    before_booking_check(){
      const self = this
      if(self.member_no != ''){
      self.show = true
      //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: self.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            self.member_no = response.data.member.memberId
          }
          self.booking_check()
        })
      }
    },
    booking_check(){
      const self = this
      self.history_today = true
      if(self.member_no != ''){
        this.formData = new FormData()
        this.formData.append('branch', self.branch)
        this.formData.append('member_no', self.member_no)
        this.axios
        .post('/booking/history', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          self.booking_history = response.data.data
          if(self.booking_history.length > 0 ){
            self.booking_history.forEach((item) => {
              var BDate = new Date(item.created_at)
              item.created_at = BDate.getFullYear() + '-' +('0' + (BDate.getMonth()+1)).slice(-2)+ '-' +  ('0' + BDate.getDate()).slice(-2) + ' '+('0' + (BDate.getHours())).slice(-2)+ ':'+('0' + (BDate.getMinutes())).slice(-2)+ ':'+('0' + (BDate.getSeconds())).slice(-2)
              if(item.history.length > 0){
                item.history.forEach((history) => {
                  var HDate = new Date(history.created_at)
                  history.created_at = HDate.getFullYear() + '-' +('0' + (HDate.getMonth()+1)).slice(-2)+ '-' +  ('0' + HDate.getDate()).slice(-2) + ' '+('0' + (HDate.getHours())).slice(-2)+ ':'+('0' + (HDate.getMinutes())).slice(-2)+ ':'+('0' + (HDate.getSeconds())).slice(-2)
                })
              }
              item.bookingDate = item.booking_date
              item.startDate = item.bookingDate+'T'+item.start_time+':00'
              item.endDate = item.bookingDate+'T'+item.end_time+':00'
              item.bookingStartTime = {
                hours: item.start_time.substring(0, 2),
                minutes: item.start_time.substring(3, 5),
                seconds: '00'
              }
              item.bookingEndTime = {
                hours: item.end_time.substring(0, 2),
                minutes: item.end_time.substring(3, 5),
                seconds: '00'
              }
              if(item.arrival_time){
                item.arrivalTime = {
                  hours: item.arrival_time.substring(0, 2),
                  minutes: item.arrival_time.substring(3, 5),
                  seconds: '00'
                }
              }else{
                item.arrivalTime = {
                  hours: '00',
                  minutes: '00',
                  seconds: '0'
                }
              }

              // Advisor array
              var advisor = []
              if(item.advisor){
                var advisor_re = item.advisor
                advisor_re = advisor_re.replace('[', '')
                advisor_re = advisor_re.replace(']', '')
                advisor_re = advisor_re.replace('"', '')
                advisor = advisor_re.split(',')
              }
              item.advisor = advisor

              // Chinese Medicine array
              var chinese_medicine = []
              if(item.chinese_medicine){
                var chinese_medicine_re = item.chinese_medicine
                chinese_medicine_re = chinese_medicine_re.replace('[', '')
                chinese_medicine_re = chinese_medicine_re.replace(']', '')
                chinese_medicine_re = chinese_medicine_re.replace('"', '')
                chinese_medicine = chinese_medicine_re.split(',')
              }
              item.chinese_medicine = chinese_medicine

              // Nutritionist array
              var nutritionist = []
              if(item.nutritionist){
                var nutritionist_re = item.nutritionist
                nutritionist_re = nutritionist_re.replace('[', '')
                nutritionist_re = nutritionist_re.replace(']', '')
                nutritionist_re = nutritionist_re.replace('"', '')
                nutritionist = nutritionist_re.split(',')
              }
              item.nutritionist = nutritionist

              // Machine array
              var machine = []
              if(item.machine){
                var machine_re = item.machine
                machine_re = machine_re.replace('[', '')
                machine_re = machine_re.replace(']', '')
                machine_re = machine_re.replace('"', '')
                machine = machine_re.split(',')
              }
              item.machine = machine
            })
          }
          document.getElementById('searchbookingbutton').click()
          self.show = false
        }).catch((error) => { 
          console.error(error)
          self.show = false
        })
      }
    },
    getBookingHistory(data){
      const self = this
      if(data.history.length == 0){
        self.show = true
        this.formData = new FormData()
        this.formData.append('id', data.id)
        this.axios
        .post('/booking/historyDetail', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          data.history = response.data.data
          if(data.history.length > 0){
            data.history.forEach((history) => {
              var HDate = new Date(history.created_at)
              history.created_at = HDate.getFullYear() + '-' +('0' + (HDate.getMonth()+1)).slice(-2)+ '-' +  ('0' + HDate.getDate()).slice(-2) + ' '+('0' + (HDate.getHours())).slice(-2)+ ':'+('0' + (HDate.getMinutes())).slice(-2)+ ':'+('0' + (HDate.getSeconds())).slice(-2)
            })
          }
        }).catch((error) => { 
          console.error(error)
          self.show = false
        })
      }
      self.get_history(data)
    },
    get_history(data) {
      const self = this
      self.selected_history= []
      self.selected_history.push(data)
      self.show = false
      document.getElementById('bookinghistorybutton').click()
    },
    roster_check(){
      const self = this
      var search_booking = []
      var temp_array = []
      setTimeout(function () {
        var difference = self.old_business_day_roster.filter(x => !self.business_day_roster.includes(x))
        if(difference.length > 0){
          difference.forEach((therapist) => {
            search_booking = self.business_day_roster_booking.filter(function (booking) {
              return booking.therapist == therapist && booking.status == 1
            })
            if(search_booking.length > 0){
              self.business_day_roster.push(therapist)
              temp_array.push(therapist)
            }
          })
          if(temp_array.length > 0){
            self.roster_booked = self.getlistitem('therapist', temp_array) + ' 在該日有booking'
            setTimeout(function () {
              self.roster_booked = ''
            }, 5000)
          }
        }
      }, 500)
    },
    change_bookingStartTime(){
      const self = this
      if(self.eventInfo.bookingStartTime.minutes == "30"){
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.bookingEndTime.minutes = "00"
      }else{
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours
        self.eventInfo.bookingEndTime.minutes = "30"
      }
      self.eventInfo.start_time = self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')
      self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      self.room_check()
      self.therapist_check()
    },
    change_bookingEndTime(){
      const self = this
      if((self.eventInfo.bookingStartTime.hours > self.eventInfo.bookingEndTime.hours) || (self.eventInfo.bookingStartTime.hours == self.eventInfo.bookingEndTime.hours && self.eventInfo.bookingStartTime.minutes > self.eventInfo.bookingEndTime.minutes)){
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      }else{
        //self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      }
      self.room_check()
      self.therapist_check()
    },
    change_business_day(){
      const self = this
      self.roster_booked = ''
      var business_day = self.business_day
      self.default_roster = '0'
      self.roster_submitted = false
      self.old_business_day_roster = []
      self.business_day_roster = []
      self.business_day_roster_booking = []
      if (self.business_day.length != 10) {
        var fd_offset = self.business_day.getTimezoneOffset()
        var date = new Date(self.business_day.getTime() - fd_offset * 60 * 1000)
        business_day = date.toISOString().split('T')[0]
      }
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', business_day)
      this.formData.append('end_booking_date', business_day)
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        if(response.data.roster_list.length > 0 ){
          self.roster_submitted = true
          self.business_day_roster_booking = response.data.data
          response.data.roster_list.forEach((item) => {
            self.old_business_day_roster.push(item.id)
            self.business_day_roster.push(item.id)
          })
        }
      })
    },
    change_bookingDate(info){
      const self = this
      var old_therapist = info.org_therapist
      info.org_therapist = []
      if (info.bookingDate.length != 10) {
        var fd_offset = info.bookingDate.getTimezoneOffset()
        var date = new Date(info.bookingDate.getTime() - fd_offset * 60 * 1000)
        info.bookingDate = date.toISOString().split('T')[0]
      }
      self.therapist_list = [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', info.bookingDate)
      this.formData.append('end_booking_date', info.bookingDate)
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            item.text = item.nickname
            item.name = item.nickname
            item.color = '#ededed'
            self.therapist_list.push(item)
          })
          old_therapist.forEach((item) => {
            var index = self.therapist_list.findIndex(el => el.id == item)
            if(index >= 0){
              info.org_therapist.push(item)
            }
          })
        }
        self.bookingDatedataSource = response.data.data
        self.room_check()
        self.therapist_check()
      })
    },
    home_page(){
      document.getElementById('confirmModal_close').click()
    },
    getName(staff){
      const self = this
      var search_branch = self.branch_list.filter(function (item) {
        return item.id == staff.shop
      })
      if(search_branch.length > 0){
        return staff.name + ' (' + search_branch[0].branch + ')'
      }else{
        return staff.name
      }
    },
    count_room_bytime(){
      const self = this
      self.count_room_list = []
      var count_config = self.config_list.find(i => i.id == 2)
      if(count_config){
        var begin = new Date(self.currentDateFormat+' 11:00')
        var end = new Date(self.currentDateFormat+' 22:30')
        for (var day = begin; day <= end; day.setMinutes(day.getMinutes() + 30)) {
          const offset = day.getTimezoneOffset()
          var offsettime = new Date(day.getTime() - (offset*60*1000))
          var hour =  offsettime.toISOString().split('T')[1].substring(0, 2)
          var minutes =  offsettime.toISOString().split('T')[1].substring(3, 5)
          var hourafter30 = offsettime.toISOString().split('T')[1].substring(0, 2)
          var minutesafter30 =  offsettime.toISOString().split('T')[1].substring(3, 5)
          if(minutes == '30'){
            hourafter30 = parseInt(hour) + 1
            minutesafter30 = '00'
          }else{
            minutesafter30 = '30'
          }
          var starttime = hour+':'+minutes
          var endtime = hourafter30+':'+minutesafter30
          var list = self.dataSource.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            ))
          )
          var used_room_M = list.filter(function (item) {
            return item.special_event == 0 && item.status > 0 && ((starttime >= item.start_time && item.end_time > starttime) || (starttime < item.start_time && endtime > item.start_time)) && item.gender == 'M' && item.therapist != '9999' && item.therapist != '99999'
          })
          var used_room_F = list.filter(function (item) {
            return item.special_event == 0 && item.status > 0 && ((starttime >= item.start_time && item.end_time > starttime) || (starttime < item.start_time && endtime > item.start_time)) && item.gender == 'F' && item.therapist != '9999' && item.therapist != '99999'
          })
          var used_room = list.filter(function (item) {
            return item.special_event == 0 && item.status > 0 && ((starttime >= item.start_time && item.end_time > starttime) || (starttime < item.start_time && endtime > item.start_time))
          })
          var count_roomInfo = {
            time: starttime,
            used_room: used_room.length,
            used_room_M: used_room_M.length,
            used_room_F: used_room_F.length,
          }
          self.count_room_list.push(count_roomInfo)
        }
      }
      self.refreshDataGrid()
    },
    get_count_name(data){
      const self = this
      var count_config = self.config_list.find(i => i.id == 2)
      if(count_config){
        const offset = data.date.getTimezoneOffset()
        var offsettime = new Date(data.date.getTime() - (offset*60*1000))
        var hour =  offsettime.toISOString().split('T')[1].substring(0, 2)
        var minutes =  offsettime.toISOString().split('T')[1].substring(3, 5)
        var starttime = hour+':'+minutes
        if(!data.used_room_M || !data.used_room_F || !data.used_room){
          if(self.count_room_list.length > 0){
            var list = self.count_room_list.filter(function (item) {
              return item.time == starttime
            })
            if(!data.used_room_M){
              data.used_room_M = list[0].used_room_M+'/'+self.room_list_M.length
            }
            if(!data.used_room_F){
              data.used_room_F = list[0].used_room_F+'/'+self.room_list_F.length
            }
            if(!data.used_room){
              data.used_room = list[0].used_room+'/'+self.room_list.length
            }
          }
        }
      }
      return data.text
    },
    default_list(){
      const self = this
      if(self.default_roster == 1){
        self.business_day_roster = []
        var list = self.full_therapist_list.filter(function (item) {
          return item.shop == self.branch_id
        })
        if(list.length > 0 ){
          list.forEach((item) => {
            self.business_day_roster.push(item.id)
          })
        }
      }
    },
    loadBookingPDF() {
      const self = this
      let postConfig = {
        responseType: 'blob',
      }
      this.formData = new FormData()
      this.formData.append('date', self.currentDateFormat)
      this.formData.append('branch', self.branch)
      this.axios
        .post('/booking/print', this.formData, postConfig)
        .then(function (response) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          //link.setAttribute("download", self.branch+"_"+self.currentDateFormat+".pdf")
          document.body.appendChild(link)
          link.click()
        })
    },
    gen_report(){
      const self = this
      var dataset = {
        type : 'booking_report_count_hourly',
        name : 'Booking Report Hourly',
        start_date : self.currentDateFormat,
        end_date : self.currentDateFormat
      }
      this.downloadExcel(dataset)
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.modal {
  top: -20px !important;
}

.dx-scheduler{
  height: calc(100vh - 115px) !important;
}

.dx-scheduler-cell-sizes-vertical {  
  height: 70px !important;
}

.dx-scheduler-cell-sizes-horizontal{
  width: 220px !important;
  height: 70px !important;
}

.dx-scheduler-appointment {
  color: black !important;
  border-left: 0px !important;
}

.dx-scheduler-appointment-content {
  padding: 0px 0px !important;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 18px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  height: 18px !important;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 18px !important;
}

.disable-date {
  background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.2) 4px, transparent 4px, transparent 9px );
}

.genderM {
  border-left: 10px solid #2881ed !important;
  border-top: 10px solid #2881ed !important;
}

.genderF {
  border-left: 10px solid #d4264f !important;
  border-top: 10px solid #d4264f !important;
}

.genderMBG{
  background-color: #a9e7f3 !important;
}

.newJoin {
  background-color: #ebeb38;
}

.CallTeam {
  background-color: #50d43a;
}

.WTSTeam {
  background-color: #ff9f55;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}

.fa-phone  {
  color: rgb(4, 97, 66);
}

.fa-star  {
  color: #c9c930;
}

.drag {
  box-sizing: border-box;
  padding: 3px 5px;
  margin-bottom: 5px;
}

.offcanvas-width{
  width: 550px !important;
}

.offcanvas-width2{
  width: 700px !important;
}

.offcanvas-width4{
  width: 550px !important;
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
.without_therapist {
  color: red;
  font-size: 16px;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update2 {
  color: white;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}
</style>